import React from 'react';

//SDS
import AppLogo from 'saur-design-sys-web/lib/Atoms/AppLogo/AppLogo';
import { Brands } from 'saur-design-sys-web/lib/BrandsConfig';

interface IProps {
    className?: string;
    logoName: Brands;
}

const BrandLogo = ({ logoName, className }: IProps) => {
    return (
        <AppLogo  
            version={2} 
            content='footer'
            className={className}
            forceBrand={logoName}
        />
    );
};

export default BrandLogo;
