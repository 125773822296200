import configStore from 'saur-viseau-state-management/lib';
import { initialState as userProfileInitialState } from 'saur-viseau-state-management/lib/user-profile/state';
import { initialState as settingsInitialState } from 'saur-viseau-state-management/lib/settings/state';
import { createTransform } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import settingsEffetcs from './store/settingsEffetcs';
import reduxLogger from 'redux-logger';

export const initialStates = {
    userProfile: userProfileInitialState,
    settings: settingsInitialState
};

export type StateName = 'userProfile' | 'settings';

const localsagas: Array<any> = [settingsEffetcs];

const savedState = (key: StateName, state: any) => {
    const saveds: Array<StateName> = ['settings'];
    if (state && saveds.includes(key)) {
        return {
            ...initialStates[key],
            ...state
        };
    }
    if (state && !saveds.includes(key)) {
        return initialStates[key];
    }
    if (!state) {
        return initialStates[key];
    }
};

const filterInCommingStateToStorage = (key: StateName, state: any) => {
    switch (key) {
        case 'settings':
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

const GeneralSettings = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: any, key: StateName) => {
        const next = filterInCommingStateToStorage(key, inboundState);
        // convert mySet to an Array.
        return savedState(key, next);
    },
    (outboundState, key: StateName) => {
        // convert mySet back to a Set.
        return savedState(key, outboundState);
    }
);

// custom config for react-native with AsyncStorage
const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    transforms: [GeneralSettings]
};

const middlewares =
    process.env.REACT_APP_DEBUG_MODE === 'true' ? [reduxLogger] : [];

// Initalize the redux store
const { store, persistor, PersistGate } = configStore(
    middlewares,
    // @ts-ignore
    persistConfig
);

//initSagaMiddleware(localsagas)

export { store, persistor, PersistGate, localsagas };
