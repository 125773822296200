import React from 'react';
//Design-sys
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Loader from 'saur-design-sys-web/lib/Atoms/Loader/Loader';

//modules
import AppMenu from '../components/AppMenu';


const DisplayAppMenu = ({displayAppMenu, toggleMenu, collapseMenu, visibleMobileMenu, desktopMenuStateVar}: any) => {

    return(
            displayAppMenu ? (
            <Container className={`left-panel`}>
                <AppMenu
                    toggleMenu={toggleMenu}
                    collapseMenu={collapseMenu}
                    menuStatus={visibleMobileMenu}
                    desktopMenuState={desktopMenuStateVar}
                />
            </Container>
        ):
        <></>
    )
}

const LoadingContent = ({showContent}: any) => {

    return (
        !showContent ?
            <Container className='right-panel content-is-loading'>
                <Container
                    className='LoaderScreen'
                    automatedTestId='LoaderScreen-testId'
                >
                    <Loader className='LoaderScreenLoader' />
                </Container>
            </Container>
        :
            <></>
    )

}

const EmbedNotifications = ({isAlertsFeaterEnabled, lang, theme, accessToken, device}: any) => {
    return (
        !!isAlertsFeaterEnabled ?
            <notifications-widget
                lang={lang}
                theme={theme}
                runtime='full'
                token={accessToken}
                device={device}
            ></notifications-widget>
            :
            <></>

    )
}


export { DisplayAppMenu, LoadingContent, EmbedNotifications }