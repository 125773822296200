import React from 'react';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';

//CPOSM
import { getUserProfileData } from 'saur-viseau-state-management/lib/user-profile/selectors';
import { States as ProfileStates } from 'saur-viseau-state-management/lib';

//SDS
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import RightArrow from 'saur-design-sys-web/lib/Icons/RightArrow';
import SettingsIcon from 'saur-design-sys-web/lib/Icons/SettingsIcon';
import { device } from 'saur-design-sys-web/lib/Theme';
import { Brands } from 'saur-design-sys-web/lib/BrandsConfig';

//Internal
import BrandLogo from './BrandLogo';
import TestIds from '../../../../automated-tests-ids/ids';
import UserProfileData from 'saur-viseau-state-management/lib/user-profile/models/ProfileUserInformation';

//Type
interface IProps {
    communityId: string;
    logoName?: Brands;
    communityName: string;
    onSelectCommunity: Function;
    gotoAdministration: Function;
}

const ListRow = ({
    communityId,
    logoName,
    communityName,
    onSelectCommunity,
    gotoAdministration
}: IProps) => {
    const userProfileData = useSelector((state: ProfileStates) =>
        getUserProfileData(state)
    ) as UserProfileData;
    //handlers
    const handleSettingsClicked = () => {
        gotoAdministration(communityId);
    };
    const handleOnCommunitySelected = () => {
        onSelectCommunity(communityId);
    };

    // "saurad_cpo_consult","saurad_cpo_admin","saurad_cpo_super-admin"
    //afficher pour les utilisateurs internes
    const showSettingsBtn = userProfileData?.userType == 'CPO_Saur' && (userProfileData?.clientRoles?.includes("saurad_cpo_admin") || userProfileData?.clientRoles?.includes("saurad_cpo_super-admin")); 

    return (
        <Container className='communitiesListItem'>
            <Container
                className='communitiesListItem__row'
                automatedTestId={TestIds.menu.communityItem}
            >
                <Container className='communitiesListItem__icon'>
                    <BrandLogo logoName={logoName || 'saur'} />
                </Container>
                <Container className='communitiesListItem__name'>
                    <Word textStyle='title' value={communityName} />
                </Container>

                {showSettingsBtn && (
                    <Container
                        automatedTestId={TestIds.menu.communitySettingBtn}
                        className='communitiesListItem__action'
                        onClick={handleSettingsClicked}
                    >
                        <SettingsIcon />
                    </Container>
                )}
                <Container
                    automatedTestId={TestIds.menu.communitySelectBtn}
                    className='communitiesListItem__action'
                    onClick={handleOnCommunitySelected}
                >
                    <RightArrow />
                </Container>
            </Container>
            <Container className='communitiesListItem__devider'></Container>
        </Container>
    );
};

export const ListRowStyles = css`
    .sds-container {
        .communitiesListItem {
            .communitiesListItem__row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 5px 0;
                height: 60px;

                @media ${device.mobile} {
                    gap: 5px;
                }
                @media ${device.tablet} {
                    gap: 20px;
                }

                .communitiesListItem__icon {
                    width: 160px;
                    svg {
                        width: 100%;
                    }
                }
                .communitiesListItem__name {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .communitiesListItem__action {
                    cursor: pointer;
                    padding: 2px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 0;
                    &:hover {
                        opacity: 0.5;
                    }
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .communitiesListItem__devider {
                border: 1px #a0d9f6 solid;
            }
        }
    }
`;

export default ListRow;
