import Keycloak from 'keycloak-js';

let authProvider: Keycloak | undefined = undefined;

export type TokensType = {
    idToken: string;
    refreshToken: string;
    token: string;
};

const initKeycloakProvider = (): Keycloak => {
    return new Keycloak(
        JSON.parse(
            localStorage.getItem(providerLocalstorageKey) ??
                JSON.stringify({
                    url: process.env.REACT_APP_URL_KEYCLOAK_AUTH,
                    realm: process.env.REACT_APP_KEYCLOAK_REALM,
                    clientId: process.env.REACT_APP_KEYCLOAK_CPO_CLIENT_ID
                    // possible os : REACT_APP_KEYCLOAK_CPO_CLIENT_ID_INTERNE | REACT_APP_KEYCLOAK_CPO_CLIENT_ID
                })
        )
    );
};

export const getAuthProvider = (): Keycloak => {
    // eslint-disable-next-line no-undef
    if (typeof authProvider === 'undefined') {
        authProvider = initKeycloakProvider();
    }
    return authProvider;
};

export interface KeyCloakProvider {
    url: string;
    realm: string;
    clientId: string;
}

export const providerLocalstorageKey = 'provider';
