import { FC } from 'react';
import { css } from 'styled-components';
//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
//CPOSM
import { Claim } from 'saur-viseau-state-management/lib/settings/state';
//Internal
import {
    MenuItemType,
    RouteTypes,
    useMenuItem
} from '../../../common/navigation';
import CustomIcon from '../../CustomIcon';
import CustomLink from '../../CustomLink';

type PrimaryMenuItemProps = {
    claims: Claim[];
    handleClickMenu: (itemType: RouteTypes) => void;
    desktopMenuState: string;
    handleMouseOver: (itemType: string) => void;
    routeOver: string | undefined;
};

const PrimaryMenuItem: FC<PrimaryMenuItemProps> = ({
    claims,
    handleClickMenu,
    desktopMenuState,
    handleMouseOver,
    routeOver
}) => {
    const { getMenuItems } = useMenuItem();

    const menuItems = getMenuItems();

    return (
        <Container className='primary-menu-item-container'>
            {menuItems.map((item: MenuItemType, index: number) => (
                <CustomLink
                    automatedTestId={item.route}
                    key={item.name}
                    to={item.path}
                    onClick={() => handleClickMenu(item.route)}
                    state={desktopMenuState === 'full' ? 'full' : 'collapse'}
                    onMouseOver={() => handleMouseOver(item.route)}
                    onMouseOut={() => handleMouseOver('')}
                >
                    <Container className={`button-children`}>
                        <Container
                            className={`icon ${
                                desktopMenuState === 'full'
                                    ? 'full'
                                    : 'collapse'
                            }`}
                        >
                            <CustomIcon
                                to={item.path}
                                route={item.route}
                                routeOver={routeOver}
                            ></CustomIcon>
                        </Container>
                        {desktopMenuState === 'full' && (
                            <Word
                                value={item.name}
                                textStyle='normalMed'
                                className='menu-text'
                            />
                        )}
                    </Container>
                </CustomLink>
            ))}
        </Container>
    );
};

export const PrimaryMenuItemStyles = css`
    .primary-menu-item-container {
        flex-direction: column;
    }

    .button-children {
        flex-direction: row;
        align-items: center;
    }
`;

export default PrimaryMenuItem;
