import React, { FC } from 'react';
import { css } from 'styled-components';

// SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import { ThemeType } from 'saur-design-sys-web/lib/BrandsConfig';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';

// CPOSM


export type SwitcherProps = {
    documentType: "document" | "sharepoint";
    toggleDocumentType: any;
    isDocumentSelectable: boolean;
    isSharepointSelectable: boolean;
};

const Switcher: FC<SwitcherProps> = ({
    toggleDocumentType,
    documentType,
    isDocumentSelectable,
    isSharepointSelectable
}) => {
    return (
        <Container
            className='switcher-filter-container'
            data-testid='SwitcherConyainerID'
        >
            <Container className='switcher-filter-block switcher-filter-options'>
                
                <Container
                    className={`switcher-filter-option ${
                        documentType === "sharepoint" && 'active'
                    } ${!isSharepointSelectable && 'disabled'} `}
                    onClick={() => toggleDocumentType("sharepoint")}
                    automatedTestId='SwitcherSharepointID'
                >
                    <Word
                        value={"Sharepoint"}
                        textStyle='normalBold'
                    />
                </Container>
                <Container
                    className={`switcher-filter-option ${
                        documentType === "document" && 'active'
                    } ${!isDocumentSelectable && 'disabled'} `}
                    onClick={() => toggleDocumentType("document")}
                    automatedTestId='SwitcherDocumentID'
                >
                    <Word
                        value={"Document"}
                        textStyle='normalBold'
                    />
                </Container>
            </Container>
        </Container>
    );
};

export default Switcher;

export const SwitcherStyles = css`
    .sds-container.switcher-filter-container {
      
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        height: 70px;
        .multiselect-container {
            width: 360px;
            z-index: 9999;
        }
        
        .switcher-filter-block {
            height: 54px;
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 8px 24px 0 rgb(18 26 49 / 8%);
            overflow: hidden;
            position: relative;
            padding: 4px;
            width: fit-content;
        }

        .switcher-filter-options {
            display: flex;
            flex-direction: row;
                width: 100%;
            .switcher-filter-option {
            width: 50%;
            text-align: center;
                padding: 15px;
                margin: 4px;
                border-radius: 20px;
                font-size: 16px;
                font-weight: bold;
                line-height: 18px;
                cursor: pointer;
                transition: 0.2s;
                justify-content: center;
                &.active {
                    background: ${(props: ThemeType) =>
                        props.theme.colors.primaryDark};
                    transition: 0.2s;
                    .sds-word {
                        color: white;
                    }
                }
                &.disabled {
                    cursor: default;
                    .sds-word {
                        color: gray;
                    }
                }
            }
        }
    }
`;
