/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, FC } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
//SDS
import { getTheme, Brands } from 'saur-design-sys-web/lib/BrandsConfig';
import AtomsStyles from 'saur-design-sys-web/lib/Atoms/Styles';
import MoleculesStyles from 'saur-design-sys-web/lib/Molecules/Styles';
import OrganismsStyles from 'saur-design-sys-web/lib/Organisms/Styles';
import ResetMeyer from 'saur-design-sys-web/lib/Common/ResetMeyer';
import GlobalStyles from 'saur-design-sys-web/lib/Common/GlobalStyles';
import GlobalFonts from 'saur-design-sys-web/lib/Common/GlobalFonts';
import ResetInputs from 'saur-design-sys-web/lib/Common/ResetInputs';
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Loader from 'saur-design-sys-web/lib/Atoms/Loader/Loader';
//CPOsm
import { initSagaMiddleware, States } from 'saur-viseau-state-management/lib';
//Internal
import Navigation from './components/Navigation';
import AppStyles from './AppStyles';
import { store, persistor, PersistGate, localsagas } from './configStore';

window.React = React;
window.ReactDOM = ReactDOM;

initSagaMiddleware(localsagas);

// it dispatch the theme configuration into all children
const ThemeWrapper: FC<{ children: ReactElement }> = ({ children }) => {
    const theme = useSelector(
        (state: States) => state.settings.theme
    ) as Brands;
    return <ThemeProvider theme={getTheme(theme)}>{children}</ThemeProvider>;
};

// it dispatch the store and localstorage data into all children
const StoreWrapper: FC<{ children: ReactElement }> = ({ children }) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>
);

// Main App
const StoreProvider = () => (
    <StoreWrapper>
        <App />
    </StoreWrapper>
);

const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    const errorMsg = useSelector((state: States) => state.settings?.errorMsg);
    const loading = useSelector((state: States) => state.settings?.loading);

    function renderLoading() {
        return (
            <Container className='main-loader'>
                <LoadingStyles />
                <Loader />
            </Container>
        );
    }

    function renderApp() {
        return (
            <>
                <Router>
                    <Navigation />
                </Router>
            </>
        );
    }

    function switchApp(): any {
        if (isProduction && !errorMsg) {
            if (process.env.REACT_APP_MATOMO_ID) {
                const instanceMatomo = createInstance({
                    urlBase: process.env.REACT_APP_MATOMO_URL as string,
                    siteId: Number(process.env.REACT_APP_MATOMO_ID),
                    configurations: {
                        disableCookies: false
                    }
                });
                return (
                    <MatomoProvider value={instanceMatomo}>
                        {renderApp()}
                    </MatomoProvider>
                );
            } else {
                return renderApp();
            }
        } else if (!isProduction) {
            return renderApp();
        } else if (loading) {
            return renderLoading();
        } else if (errorMsg) {
            localStorage.clear();
            return renderError();
        }
        return <Container className='undefined-statement' />;
    }

    function renderError() {
        return window.location.assign('/maintenance.html');
    }

    return (
        <ThemeWrapper>
            <>
                <ResetInputs />
                <GlobalFonts />
                <GlobalStyles />
                <ResetMeyer />
                <AtomsStyles />
                <MoleculesStyles />
                <OrganismsStyles />
                <AppStyles />
                {switchApp()}
            </>
        </ThemeWrapper>
    );
};

const LoadingStyles = createGlobalStyle`
.sds-reset-style{
  .main-loader {
    position: fixed;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%; 
    transform: translateX(-50%) translateY(-50%);   
  }
}
`;

export default StoreProvider;
