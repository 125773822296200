import { FC } from 'react';
import { useSelector } from 'react-redux';
import { css } from 'styled-components';
//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Button from 'saur-design-sys-web/lib/Atoms/Input/Button/Button';
import AppLogo from 'saur-design-sys-web/lib/Atoms/AppLogo/AppLogo';

//CPOSM
import { States } from 'saur-states-management';
import {
    device,
    onlyForDesktop,
    onlyForMobile
} from 'saur-design-sys-web/lib/Theme';

//Internal
import { DesktopMenuState, ModalMode} from '../../../common/customTypes';

type LogoContainerProps = {
    desktopMenuState: string;
    toggleMenu: (option: ModalMode) => void;
    collapseMenu: (style: DesktopMenuState) => void;
};

const LogoContainer: FC<LogoContainerProps> = ({
    desktopMenuState,
    toggleMenu,
    collapseMenu
}) => {
    const settingState = useSelector((state: States) => state.settings);
    const logoName = settingState?.params?.LogoName || "saur"
    const arrowUrl =
        desktopMenuState === 'full'
            ? `/assets/arrow-left.svg`
            : `/assets/arrow-right.svg`;

    return (
        <Container
            className={`logo-container ${
                desktopMenuState === 'full' ? 'full' : 'collapse'
            }`}
        >
            <AppLogo
                forceBrand={logoName}
                version={2}
                type='lighten'
                content='header'
                className={`app-logo ${
                    desktopMenuState === 'full' ? 'full' : 'collapse'
                }`}
            />
            <Button
                mode='basic'
                className='close-menu-button'
                onClick={() => toggleMenu('in')}
            >
                <img
                    src='/assets/close-menu-icon.svg'
                    alt='close-menu-mobile'
                />
            </Button>
            <Button
                mode='basic'
                className={`collapse-menu-button ${
                    desktopMenuState === 'full' ? 'full' : 'collapse'
                }`}
                onClick={() => {
                    collapseMenu(
                        desktopMenuState === 'full' ? 'collapse' : 'full'
                    );
                }}
            >
                <img src={`${arrowUrl}`} alt='collapse-menu-mobile' />
            </Button>
        </Container>
    );
};

export const LogoContainerStyles = css`
    .app-logo {
        &.collapse {
            .register {
                svg {
                    @media ${device.tablet} {
                        width: 60px;
                        height: auto;
                    }
                }
            }
        }

        .register {
            width: auto;
            height: auto;

            svg {
                @media ${device.mobile} {
                    width: 80px;
                    height: auto;
                }
                @media ${device.tablet} {
                    width: 100px;
                    height: auto;
                }
            }
        }
    }
    .logo-container {
        flex-direction: row;
        justify-content: space-between;
        @media ${device.tablet} {
            margin-top: 40px;
        }

        &.collapse {
            flex-direction: column;
        }
    }
    .close-menu-button {
        ${onlyForMobile}
        align-self: flex-end;
    }
    .collapse-menu-button {
        ${onlyForDesktop}
        align-self: center;

        &.collapse {
            margin-top: 40px;
        }
    }
`;

export default LogoContainer;
