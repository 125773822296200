import React, { useEffect } from 'react';
import i18next from 'i18next';

//CPOSM
import Community from 'saur-viseau-state-management/lib/common/models/Community';

//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import SearchIcon from 'saur-design-sys-web/lib/Icons/SearchIcon';

//Internal
import TestIds from '../../../automated-tests-ids/ids';
import { sortBy } from '../../../common/utils';

type Props = {
    showSearchBar: boolean;
    setfilteredCommunities: any
    searchTerm: string
    setSearchTerm: (text: string) => void
    fullCommunitiesList: Community[]
};
const SearchContainer = ({ showSearchBar, setfilteredCommunities, searchTerm, setSearchTerm, fullCommunitiesList }: Props) => {

    useEffect(() => {
        setfilteredCommunities(filterCommunitiesList(searchTerm));
    }, [searchTerm, fullCommunitiesList]);
    
    useEffect(() => {
        setfilteredCommunities(sortBy(fullCommunitiesList, "longName", 1))
    },[])
    
    const filterCommunitiesList = (needle: string) => {
        if (needle) {
            let  filtredListData  = fullCommunitiesList.filter((community: Community) => {
                return community.longName?.toLowerCase().includes(needle.toLowerCase()) ||
                 community.contracts.find(({contractReference}) => contractReference?.includes(needle.toLowerCase()))
            })
            filtredListData = sortBy(filtredListData, "longName", 1)

            return  filtredListData
        } 

        return sortBy(fullCommunitiesList, "longName", 1)
    };
    return showSearchBar ? (
        <Container className='communitiesList__SearchBar'>
            <input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                data-testid={TestIds.menu.searchCommunityList}
                placeholder={i18next.t(
                    'menu.changeCommunity.modal.searchPlaceholder'
                )}
            />
            <SearchIcon />
        </Container>
    ) : (
        null
    );
};


export default SearchContainer