import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
//SVSM
import { States } from 'saur-viseau-state-management/lib';
import CommunitySharepointInfo from 'saur-viseau-state-management/lib/settings/models/CommunitySharepointInfo';
import {
    getCommunitySharpointInfo,
    getUserClaims,
    isFeatureEnabled
} from 'saur-viseau-state-management/lib/settings/selectors';
import {
    ClaimType,
    Claim
} from 'saur-viseau-state-management/lib/settings/state';
import { hasCommunityWaterObseratory } from 'saur-viseau-state-management/lib/contracts/selectors';

export enum RouteTypes {
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    CARTOGRAPHY = 'cartography',
    REPORTINGS = 'reportings',
    DOCUMENTS = 'documents',
    NEWS = 'news',
    PROFILE = 'profile',
    LOGINSSO = 'loginSso',
    MOBILELOGIN = 'mobilelogin',
    COMMUNITY_SELECTION = 'community-selection',
    REQUESTS = 'requests',
    WATER_OBSERVATORY = 'water-observatory',
    FICHE_CLIENT = 'fiche-client'
}

export function usePath() {
    function getPath(value: RouteTypes): string {
        switch (value) {
            case RouteTypes.LOGIN:
                return `/connexion`;
            case RouteTypes.LOGINSSO:
                return `/connexion-sso`;
            case RouteTypes.COMMUNITY_SELECTION:
                return `/community-selection`;
            case RouteTypes.DASHBOARD:
                return `/dashboard`;
            case RouteTypes.CARTOGRAPHY:
                return `/cartography`;
            case RouteTypes.DOCUMENTS:
                return `/documents`;
            case RouteTypes.REPORTINGS:
                return `/reportings`;
            case RouteTypes.NEWS:
                return `/news`;
            case RouteTypes.PROFILE:
                return `/profile`;
            case RouteTypes.REQUESTS:
                return `/requests`;
            case RouteTypes.MOBILELOGIN:
                return '/mobilelogin';
            case RouteTypes.WATER_OBSERVATORY:
                return '/water-observatory';
            case RouteTypes.FICHE_CLIENT:
                return `/fiche-client`;
            default:
                return `/`;
        }
    }
    return { getPath };
}

export type MenuItemType = {
    route: RouteTypes;
    name: string;
    icon: string;
    path: string;
    claimCode?: ClaimType;
};

const PRIMARY_MENU_CONFIG: MenuItemType[] = [
    {
        route: RouteTypes.DASHBOARD,
        name: 'menu.entry.dashboard',
        path: '/dashboard',
        icon: '/dashboard-menu-icon.svg'
    },
    {
        route: RouteTypes.CARTOGRAPHY,
        name: 'menu.entry.cartography',
        path: '/cartography',
        icon: '/map-menu-icon.svg',
        claimCode: ClaimType.SHOW_MAP
    },
    {
        route: RouteTypes.REPORTINGS,
        name: 'menu.entry.reportings',
        path: '/reportings',
        icon: '/reporting-menu-icon.svg',
        claimCode: ClaimType.SHOW_REPORT
    },
    {
        route: RouteTypes.WATER_OBSERVATORY,
        name: 'menu.entry.waterObservatory',
        path: `/${RouteTypes.WATER_OBSERVATORY}`,
        icon: '/water-observatory.svg'
    },
    {
        route: RouteTypes.DOCUMENTS,
        name: 'menu.entry.documents',
        path: '/documents',
        icon: '/document-menu-icon.svg',
        claimCode: ClaimType.SHOW_DOCUMENT
    },
    {
        route: RouteTypes.NEWS,
        name: 'menu.entry.news',
        path: '/news',
        icon: '/news-menu-icon.svg'
    },
    {
        route: RouteTypes.REQUESTS,
        name: 'menu.entry.requests',
        path: '/requests',
        icon: '/requests-icon.svg'
    },
    {
        route: RouteTypes.FICHE_CLIENT,
        name: "Fiche client",
        path: '/fiche-client',
        icon: '/fiche-client.svg'
    }
];

export function useMenuItem() {
    const { t } = useTranslation();
    const claims = useSelector(getUserClaims) as Claim[];

    const communitySharepointInfo = useSelector(
        getCommunitySharpointInfo
    ) as CommunitySharepointInfo;
    const showWaterObservatory = useSelector(
        hasCommunityWaterObseratory
    ) as boolean;

    const showNewsMenu = useSelector((state: States) =>
        isFeatureEnabled(state, 'Show-News-Menu')
    );

    /**
     * return the menu items that the user is allowed to access
     */
    const getMenuItems = useCallback((): MenuItemType[] => {
        // apply claims/permissions by route
        const allowedMenuItems = PRIMARY_MENU_CONFIG.filter(
            ({ route, claimCode }) => {
                switch (route) {
                    case RouteTypes.DASHBOARD:
                    case RouteTypes.REQUESTS:
                        return true; //shown all time

                    case RouteTypes.NEWS:
                        return !!showNewsMenu;

                    case RouteTypes.WATER_OBSERVATORY:
                        return !!showWaterObservatory;

                    //check code in claims
                    case RouteTypes.CARTOGRAPHY:
                    case RouteTypes.REPORTINGS:
                        return claims?.some((c) => c.code === claimCode);
                    case RouteTypes.DOCUMENTS:
                        return (
                            claims?.some((c) => c.code === claimCode) &&
                            communitySharepointInfo &&
                            communitySharepointInfo.itemId &&
                            communitySharepointInfo.siteId
                        );

                    default:
                        return false;
                }
            }
        );
        //translate menu texts
        const menuItems = allowedMenuItems.map((item) => ({
            ...item,
            name: t(item.name)
        }));

        return menuItems;
    }, [
        claims,
        communitySharepointInfo,
        showNewsMenu,
        showWaterObservatory,
        t
    ]);

    //
    return { getMenuItems };
}
