import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleLogout } from '../LogoutHelper';
import { getAuthProvider } from './authProvider';

function useExpireToken(logoutPath: string , userType: string | null | undefined) {
    const dispatch = useDispatch();
    const expires_at = JSON.parse(
        localStorage.getItem('sso-response') ?? 'null'
    )?.expires_at;

    useEffect(() => {
        if (expires_at && expires_at * 1000 < new Date().getTime()) {
            handleLogout(
                dispatch,
                getAuthProvider(),
                `${window.location.origin}${logoutPath}`,
                userType
            );
        }
    });
}

export { useExpireToken };
