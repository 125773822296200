import { settingsActions } from 'saur-viseau-state-management/lib/settings/state';
import Keycloak from 'keycloak-js';


export const handleLogout = async (
    dispatch: any,
    keycloak: Keycloak | undefined,
    redirectUri: string,
    userType: string | null | undefined
) => {
    try {
        const clearStorage = () => {
            localStorage.clear();
            sessionStorage.clear();
        };

        const logout = (internalUser: boolean, idToken?: string) => {
            clearStorage();
            dispatch(settingsActions.logout(internalUser));
            setTimeout(() => {
                dispatch(settingsActions.reset());
                document.cookie =
                    'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                let logoutUrl = redirectUri;
                if (!internalUser) {
                    if (keycloak && typeof keycloak.createLogoutUrl === 'function') {
                        logoutUrl = `${keycloak.createLogoutUrl()}?redirect_uri=${redirectUri}&id_token_hint=${idToken}`;
                    }
                }
                window.location.replace(logoutUrl);
            }, 100);
        };

        if (userType === 'CPO_Saur') {
            logout(true);
        } else {
            if (keycloak) {
                const idToken = JSON.parse(
                    localStorage.getItem('sso-response') ?? 'null'
                )?.id_token;
                logout(false, idToken);
            }
        }
    } catch (error) {
        debugger;
        console.error('Erreur lors de la déconnexion :', error);
    }
};

