import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteTypes, usePath } from '../common/navigation';
import { langTypes } from 'saur-states-management/lib/i18n';
import { useHistory } from 'react-router-dom';
import { getLanguage } from 'saur-states-management/lib/selectors/settingsSelectors';

import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';

type LoginSsoScreenProps = {};

const LoginSsoScreen: FC<LoginSsoScreenProps> = () => {
    const [token, setToken] = useState<string | null>(
        JSON.parse(localStorage.getItem('sso-response') ?? 'null')?.access_token
    );
    const lang = useSelector(getLanguage) as langTypes;
    const { getPath } = usePath();
    const history = useHistory();

    useEffect(() => {
        window.addEventListener('storage', (e) => {
            let accessToken = JSON.parse(
                localStorage.getItem('sso-response') ?? 'null'
            )?.access_token;
            setToken(accessToken);
            if (accessToken) {
                history.push(
                    `/${lang}${getPath(RouteTypes.COMMUNITY_SELECTION)}`
                );
            }
        });
    }, [getPath, history, lang, token]);

    return (
        <Container className='LoginSsoScreen'>
            <iframe
                height='0'
                id='inner'
                src={
                    process.env.REACT_APP_URL_TO_BLOBSTORAGE +
                    '/saur-ad-connect-sso.html'
                }
                title='SaurADConnectSsoIframe'
            ></iframe>
        </Container>
    );
};

export default LoginSsoScreen;
