import { takeEvery, select } from 'redux-saga/effects';
import {
    settingsActions,
    State as settingsState
} from 'saur-viseau-state-management/lib/settings/state';
import { Brands } from 'saur-design-sys-web/lib/BrandsConfig';
import { getSettingsState } from 'saur-viseau-state-management/lib/settings/selectors';

export function setFavicon(url: string) {
    let favElement = document.querySelector('#meta-favicon');
    if (favElement) {
        // @ts-ignore
        favElement.setAttribute("href" , url); 
    
    }
}

export function setFaviconByTheme(theme: Brands) {
    switch (theme) {
        case 'se3r': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-SE3R.ico`);
            break;
        }
        case 'sepig': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/sepig-16x16.ico`);
            break;
        }
        case 'accm': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-ACCM.ico`);
            break;
        }
        case 'atlantic-eau': {
            setFavicon(
                `${process.env.PUBLIC_URL}/favicons/Favicon-Atlantic.ico`
            );
            break;
        }
        case 'cer': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-CER.ico`);
            break;
        }
        case 'cgsp': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-CGSP.ico`);
            break;
        }
        case 'cise-reunion': {
            setFavicon(
                `${process.env.PUBLIC_URL}/favicons/Favicon-cise-réunion.ico`
            );
            break;
        }
        case 'eau-de-garonne': {
            setFavicon(
                `${process.env.PUBLIC_URL}/favicons/Favicon-Garonne.ico`
            );
            break;
        }
        case 'grand-lac': {
            setFavicon(
                `${process.env.PUBLIC_URL}/favicons/Favicon-Grand-lac.ico`
            );
            break;
        }
        case 'saint-affo': {
            setFavicon(
                `${process.env.PUBLIC_URL}/favicons/Favicon-Saint-affo.ico`
            );
            break;
        }
        case 'saint-martin': {
            setFavicon(
                `${process.env.PUBLIC_URL}/favicons/Favicon-Saint-martin.ico`
            );
            break;
        }
        case 'sateg': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Sateg.ico`);
            break;
        }
        case 'sudeau': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Sudeau.ico`);
            break;
        }
        case 'eaux-saumur-val-de-loire': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-ESVDL.ico`);
            break;
        }
        case 'saur-eau-du-morbihan': {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-EDM.ico`);
            break;
        }
        default: {
            setFavicon(`${process.env.PUBLIC_URL}/favicons/saur-16x16.ico`);
            break;
        }
    }
}

function* createFaviconEffect() {
    const _settingsState: settingsState = yield select(getSettingsState);
    if (_settingsState && _settingsState.params.BrandReference) {
        setFaviconByTheme(_settingsState.params.BrandReference);
        //setHotFixCss(settingsState.params.BrandReference)
    }
}

function* settingsEffetcs() {
    yield takeEvery(
        settingsActions.getBrandDataSuccess.type,
        createFaviconEffect
    );
}

export default settingsEffetcs;
