
export const PageNamesForMatomo = {
    login: "Authent",
    home: "Home page",
    loginSso: "login sso",
    documentsPage: "Documents",
    reportingsPage: "Reportings",
    newsPage: "Actualités",
    requestsPage : "Demandes",
    cartographyPage: "Cartographie",
    communitySelection: "community selection",
    userPage: "user page",
    profilPage: "profil page",
    mobileLoginPage: "mobile login page"
};

export function getMatomoName(pathname: string, currentLang: "fr" | "en" | 'fr_FR' | 'en_US') {

    switch (pathname) {
        case `/${currentLang}/connexion`:
            return PageNamesForMatomo.login;
        case `/${currentLang}/dashboard`:
            return PageNamesForMatomo.home;
        case `/${currentLang}/connexion-sso`:
            return PageNamesForMatomo.loginSso;
        case `/${currentLang}/community-selection`:
            return PageNamesForMatomo.communitySelection;
        case `/${currentLang}/cartography`:
            return PageNamesForMatomo.cartographyPage ;
        case `/${currentLang}/documents`:
            return PageNamesForMatomo.documentsPage;
        case `/${currentLang}/reportings`:
            return PageNamesForMatomo.reportingsPage ;
        case `/${currentLang}/news`:
            return PageNamesForMatomo.newsPage ;
        case `/${currentLang}/USERS`:
            return PageNamesForMatomo.userPage;
        case `/${currentLang}/profile`:
            return PageNamesForMatomo.profilPage;
        case `/${currentLang}/requests`:
            return PageNamesForMatomo.requestsPage ;
        case `/${currentLang}/MOBILELOGIN`:
            return PageNamesForMatomo.mobileLoginPage;
    }
}

export function trackMatomoEvent(trackEvent: any, trackedEvents: string[], setTrackedEvents: any, pathname: string, currentLang: "fr" | "en" | 'fr_FR' | 'en_US', userType: "OKTA" | "CPO_Saur" | null = null) {
    let userTypeName = ""
    if(userType === "CPO_Saur"){
        userTypeName = "Accès interne"
    }
    if(userType === "OKTA"){
        userTypeName = "Accès externe"
    }

    if(!userTypeName) return null

    let pageName 
    switch (pathname) {
        case `/${currentLang}/cartography`:
            pageName = PageNamesForMatomo.cartographyPage ;
            break
        case `/${currentLang}/documents`:
            pageName = PageNamesForMatomo.documentsPage ;
            break
        case `/${currentLang}/reportings`:
            pageName = PageNamesForMatomo.reportingsPage ;
            break
        case `/${currentLang}/news`:
            pageName = PageNamesForMatomo.newsPage;
            break
        case `/${currentLang}/requests`:
            pageName =  PageNamesForMatomo.requestsPage ;
            break
    }
    if(!pageName) return null
    
    if(!trackedEvents.includes(pageName)){
        trackEvent( {
            category:  pageName,
            action: pageName,
            name: userTypeName,
            value: 1, 
        })
        const newArray = [...trackedEvents]
        newArray.push(pageName)
        setTrackedEvents(newArray)
        
        console.log( {
            category:  pageName,
            action: pageName,
            name: userTypeName,
            value: 1, 
        })
    }else {
        return null
    }
}