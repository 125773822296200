import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//CPOSM
import useSelectedCommunity from 'saur-viseau-state-management/lib/common/hooks/useSelectedCommunity';
import { settingsActions } from 'saur-viseau-state-management/lib/settings/state';
import { States } from 'saur-viseau-state-management/lib';
import { userProfileAction } from 'saur-viseau-state-management/lib/user-profile/state';
import {
    contractsActions,
    RequestStatus
} from 'saur-viseau-state-management/lib/contracts/state';
import { getLanguage } from 'saur-viseau-state-management/lib/settings/selectors';
import { States as ProfileStates } from 'saur-viseau-state-management/lib';
import { getUserProfileData as getUserProfileDataSelector } from 'saur-viseau-state-management/lib/user-profile/selectors';
//Internal
import { getAuthProvider } from '../common/authProvider';
import { RouteTypes, usePath } from '../common/navigation';
import { handleLogout } from '../LogoutHelper';
import UserProfileData from 'saur-viseau-state-management/lib/user-profile/models/ProfileUserInformation';


/**
 * Hook to call the main APIs that should return the data neeeded by the APP to render with error
 * @returns the aggregated state of calling the APIs { errorMsg, loading }:
 */
function useInitialDataLoader() {
    const dispatch = useDispatch();
    const currentLang = useSelector(getLanguage) as string;
    const keycloak = getAuthProvider();
    const { getPath } = usePath();
    const [selectedCommunity] = useSelectedCommunity();
    const { userType } = useSelector((state: ProfileStates) => getUserProfileDataSelector(state) as UserProfileData) || {};

    const accessTokenStored = JSON.parse(
        localStorage.getItem('sso-response') ?? 'null'
    )?.access_token;

    const accessTokenSettings = useSelector(
        (state: States) => state.settings?.accessToken
    );

    const loading = useSelector(
        ({ settings, userProfile, contracts }: States) =>
            //settings?.loading ||
            settings.userClaims?.loading ||
            userProfile.getUserProfileData?.loading ||
            contracts.getCommunity.loading
    );
    const loadSucceeded = useSelector(
        ({ userProfile, contracts }: States) =>
            //settings.userClaims?.loaded &&
            userProfile.getUserProfileData?.success &&
            contracts.getCommunity.status === RequestStatus.success
    );

    const errorMsg = useSelector(
        ({ settings, contracts }: States) =>
            settings?.errorMsg ||
            contracts.getCommunity.status === RequestStatus.failure
    );

    const errorSessionExpired = useSelector(
        ({
            userProfile: { getUserProfileData },
            contracts: { getCommunity }
        }: States) =>
            (getCommunity.status === RequestStatus.failure &&
                getCommunity?.techErrorMsg?.statusCode === 401) ||
            getUserProfileData?.technicalErrorMessage?.statusCode === 401
    );

    //init access token for all states
    useEffect(() => {
        if (accessTokenStored && !accessTokenSettings) {
            dispatch(settingsActions.setAccessToken(accessTokenStored));
        }
    }, [accessTokenStored, accessTokenSettings]);

    //call settings APIs (no need to selected community)
    useEffect(() => {
        if (accessTokenSettings) {
            dispatch(settingsActions.getFeaturesConfig());
            dispatch(userProfileAction.getUserProfileData());
            dispatch(contractsActions.getCommunityList());
        }
    }, [accessTokenSettings]);

    //call settings APIs that need selected community
    useEffect(() => {
        if (accessTokenSettings && selectedCommunity) {
            dispatch(settingsActions.getBrandData());
            dispatch(settingsActions.getUserClaims());
            dispatch(settingsActions.getCommunitySharpointInfo());
            dispatch(settingsActions.getExternalApplications(false));
        }
    }, [accessTokenSettings, selectedCommunity]);

    //Logout if session expiration is detected
    useEffect(() => {
        if (errorSessionExpired) {
            handleLogout(
                dispatch,
                keycloak,
                `${window.location.origin}/${currentLang}${getPath(
                    RouteTypes.LOGIN
                )}`,
                userType
            );
        }
    }, [errorSessionExpired]);

    return { loading, loadSucceeded, errorMsg, errorSessionExpired };
}

export default useInitialDataLoader;
