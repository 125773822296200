import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Button from 'saur-design-sys-web/lib/Atoms/Input/Button/Button';
import TextAreaInput from 'saur-design-sys-web/lib/Atoms/Input/TextAreaInput/TextAreaInput';
import TextInput from 'saur-design-sys-web/lib/Atoms/Input/TextInput/TextInput';
import Loader from 'saur-design-sys-web/lib/Atoms/Loader/Loader';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import { ThemeType } from 'saur-design-sys-web/lib/BrandsConfig';
import RainIcon from 'saur-design-sys-web/lib/Icons/RainIcon';
import SentIcon from 'saur-design-sys-web/lib/Icons/SentIcon';
import BackgroundWave from 'saur-design-sys-web/lib/Illustrations/BackgroundWave';
import Card from 'saur-design-sys-web/lib/Molecules/Card/Card';
import DropDownList from 'saur-design-sys-web/lib/Molecules/DropDownList/DropDownList';
import { option } from 'saur-design-sys-web/lib/Molecules/OptionsList/OptionsList';
import FilePicker from 'saur-design-sys-web/lib/Organisms/FilePicker/FilePicker';
import HelpModal from 'saur-design-sys-web/lib/Organisms/HelpModal/HelpModal';
import { colors } from 'saur-design-sys-web/lib/Theme';
//SVSM
import {
    States as ContractStates,
    States
} from 'saur-viseau-state-management/lib';
import PartnerContract from 'saur-viseau-state-management/lib/contracts/models/PartnerContract';
import { getContractList } from 'saur-viseau-state-management/lib/contracts/selectors';
import { contractsActions } from 'saur-viseau-state-management/lib/contracts/state';
import { ContactRequestType } from 'saur-viseau-state-management/lib/settings/models/ContactRequest';
import UserMotive from 'saur-viseau-state-management/lib/settings/models/UserMotive';
import { settingsActions } from 'saur-viseau-state-management/lib/settings/state';
import { getLanguage } from 'saur-viseau-state-management/lib/settings/selectors';
//Internal
import { DesktopMenuState } from '../../common/customTypes';
import useEventListener from '../../common/useEventListener';
import { RouteTypes, usePath } from '../../common/navigation';

type HelpCardProps = {
    desktopMenuState?: DesktopMenuState;
};

type FileState = {
    fileList: File[],
    isValid: boolean | null
  }

const HelpCard: FC<HelpCardProps> = ({ desktopMenuState = 'full' }) => {
    const history = useHistory();
    const { getPath } = usePath();
    
    const { t } = useTranslation();

    const loading = useSelector(
        (state: States) => state.settings?.contactInfo?.loading
    );
    const status = useSelector(
        (state: States) => state.settings?.contactInfo?.status
    );
    const [showContactUsPopup, setShowContactUsPopup] = useState(false);

    const [selectedMotif, setSelectedMotif] = useState<UserMotive | null>();
    const [message, setMessage] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [attachement, setAttachement] = useState<FileState>({fileList:[], isValid: true});
    const [formSent, setFormSent] = useState<boolean>(false);
    const [selectedContract, setSelectedContract] = useState<PartnerContract>();

    const lang = useSelector(getLanguage) as string;
    const dispatch = useDispatch();
    const token = JSON.parse(localStorage.getItem('sso-response') ?? 'null')
        ?.access_token;

    useEventListener('requests-on-contactus-click-event', (e) => {
        toggleShowContactUsPopup();
    });

    useEventListener('load-more-blog-click-event', (e) => {
        history.push({
            pathname: `/${lang}${getPath(RouteTypes.NEWS)}`
        });
    });

    const toggleShowContactUsPopup = () => {
        setShowContactUsPopup(!showContactUsPopup);
        setFormSent(false);
        clearFields();
    };
    const userMotiveList = useSelector(
        (state: States) => state.settings.userMotiveList.value
    );

    const allContracts = useSelector((state: ContractStates) =>
        getContractList(state)
    );

    const clearFields = () => {
        setMessage('');
        setSubject('');
        setSelectedMotif(null);
    };

    const enableButton = () => {
        return [selectedMotif , subject , message ,  attachement.isValid].every(value => !!value);
    };

    const handleAttachPicking = (payload: FileState) => {
        setAttachement(payload);
    };

    const onSubmit = () => {
        if (selectedMotif?.id && selectedContract?.partnerContractId) {
            let payload: ContactRequestType = {
                motifId: selectedMotif.id,
                partnerContractId: selectedContract.partnerContractId,
                referenceContract: selectedContract.accountingReference,
                requestObject: subject+'',
                requestContent: message+'',
                withAttachement: !!attachement.fileList.length,
                attachement: attachement.fileList[0]
            };
            dispatch(settingsActions.createContactRequest(payload));
            setFormSent(true);
        }
    };

    useEffect(() => {
        if (
            status === 'success' &&
            !loading &&
            document.querySelector('requests-widget')
        ) {
            const requestWidget = document?.querySelector('requests-widget');
            const prevRefCounter = requestWidget?.getAttribute(
                'refreshcounter'
            );
            const newRefCounter = String(Number(prevRefCounter ?? 0) + 1);
            requestWidget?.setAttribute('refreshcounter', newRefCounter);
        }
    }, [status, loading]);

    useEffect(() => {
        if (token) {
            setTimeout(() => {
                dispatch(settingsActions.setAccessToken(token));
            }, 100);
            setTimeout(() => {
                dispatch(settingsActions.getUserMotiveList());
            }, 150);
            setTimeout(() => {
                dispatch(contractsActions.getContractList());
            }, 250);
        }
    }, [token, dispatch]);

    const renderSentScreen = () => {
        const ReturnBtn = (
            <Container className='center-item'>
                <Button
                    automatedTestId='back-button-success-fail'
                    mode='plain'
                    onClick={toggleShowContactUsPopup}
                >
                    <Word
                        value={t('menu.help.popup.back_button')}
                        textStyle='title'
                    />
                </Button>
            </Container>
        );
        if (status === 'success') {
            return (
                <>
                    <Container
                        className='center-item StatusContent'
                        automatedTestId='container_msgs'
                    >
                        <SentIcon />
                        <Word
                            textStyle='titleLarge'
                            value={t('menu.help.popup.sent_success_message')}
                        />
                    </Container>
                    <Container className='center-item'>
                        <Word
                            value={t(
                                'menu.help.popup.sent_success_long_message'
                            )}
                        />
                    </Container>
                    {ReturnBtn}
                </>
            );
        }
        return (
            <>
                <Container
                    className='center-item StatusContent'
                    automatedTestId='container_msgs'
                >
                    <RainIcon />
                    <Word
                        textStyle='titleLarge'
                        value={t('menu.help.popup.sent_fail_message')}
                    />
                </Container>
                <Container className='center-item'>
                    <Word value={t('menu.help.popup.sent_fail_long_message')} />
                </Container>
                {ReturnBtn}
            </>
        );
    };

    const displayModalContent = () => (
        <>
            <Container
                className='contactus-popup'
                automatedTestId='contact-popup-container'
            >
                {loading ? (
                    <Container className='LoderContainer'>
                        <Loader className='Loader' />
                    </Container>
                ) : !formSent ? (
                    <>
                        <Container
                            className='contactus-popup-desc'
                            automatedTestId='contact-popup-container'
                        >
                            <Word value={t('menu.help.popup.message')} />
                        </Container>
                        <Container className='contactus-popup-inputs'>
                            <Container className='contactus-popup-firstrow'>
                                <Container className='contactus-popup-motif'>
                                    <Word
                                        value={t('menu.help.popup.motif_label')}
                                        textStyle='normalReg'
                                    />
                                    <DropDownList
                                        values={userMotiveList?.map((e) => {
                                            return {
                                                id: e.code,
                                                value: e.code,
                                                label: e.label
                                            } as option;
                                        })}
                                        onChange={function(
                                            value: option
                                        ): void {
                                            setSelectedMotif(
                                                userMotiveList.find(
                                                    (e) =>
                                                        e.code === value.value
                                                )
                                            );
                                        }}
                                        placeHolder={t(
                                            'menu.help.popup.motif_placeholder'
                                        )}
                                    />
                                </Container>
                                {allContracts && (
                                    <Container className='contactus-popup-motif'>
                                        <Word
                                            value={t(
                                                'menu.help.popup.contract_label'
                                            )}
                                            textStyle='normalReg'
                                        />

                                        <DropDownList
                                            values={allContracts.map((e) => {
                                                return {
                                                    id: e.partnerContractId,
                                                    value: e.partnerContractId,
                                                    label: e.name
                                                } as option;
                                            })}
                                            onChange={function(
                                                value: option
                                            ): void {
                                                setSelectedContract(
                                                    allContracts.find(
                                                        (e) =>
                                                            e.partnerContractId ===
                                                            value.id
                                                    )
                                                );
                                            }}
                                            placeHolder={t(
                                                'menu.help.popup.contract_placeholder'
                                            )}
                                        />
                                    </Container>
                                )}
                            </Container>
                            <Container className='contactus-popup-firstrow'>
                                <Container className='contactus-popup-object'>
                                    <Word
                                        value={t(
                                            'menu.help.popup.object_label'
                                        )}
                                    />
                                    <TextInput
                                        automatedTestId='textinput-popup-object'
                                        className='textinput-popup-object'
                                        placeholder={t(
                                            'menu.help.popup.object_placeholder'
                                        )}
                                        onChange={function(
                                            value: string
                                        ): void {
                                            setSubject(value);
                                        }}
                                        maxLength={80}
                                    />
                                </Container>
                            </Container>
                            <Container className='contactus-popup-message'>
                                <TextAreaInput
                                    automatedTestId='textareainput-popup-object'
                                    placeholder={t(
                                        'menu.help.popup.message_placeholder'
                                    )}
                                    onChange={function(value: string): void {
                                        setMessage(value);
                                    }}
                                    maxLength={3000}
                                    minLength={3}
                                />
                            </Container>
                        </Container>

                        <Container className='contactus-popup-footer'>
                            <Container className='contactus-popup-filepicker'>
                                <FilePicker
                                    buttonLabel={t(
                                        'menu.help.popup.picker_button'
                                    )}
                                    acceptedExtensions='.jpg,.png,.pdf,.doc,.xls,.docx,.xlsx'
                                    filePlaceHolder={t(
                                        'menu.help.popup.picker_placeholder'
                                    )}
                                    maxSize={3000}
                                    onChange={handleAttachPicking}
                                    acceptedExtensionLabel={t(
                                        'menu.help.popup.picker_accepted_extension_label'
                                    )}
                                    maxSizeLabel={t(
                                        'menu.help.popup.picker_max_size_label'
                                    )}
                                    sizeUnitLabel={t(
                                        'menu.help.popup.size_unit_label'
                                    )}
                                    sizeLimitErrorMsg={t(
                                        'menu.help.popup.fileSizeLimit'
                                    )}
                                    extentionErrorMsg={t(
                                        'menu.help.popup.fileType'
                                    )}
                                />
                            </Container>
                            <Button
                                className='submitButton'
                                automatedTestId='contactus-button-send'
                                mode='plain'
                                onClick={onSubmit}
                                loading={loading}
                                disabled={!enableButton()}
                            >
                                <Word
                                    value={t('menu.help.popup.send_button')}
                                    textStyle='title'
                                />
                            </Button>
                        </Container>
                    </>
                ) : (
                    renderSentScreen()
                )}

                <Container className='contactus-popup-background'>
                    <BackgroundWave />
                </Container>
            </Container>
        </>
    );

    return (
        <>
            {desktopMenuState !== 'full' && (
                <Card
                    className='help-card'
                    automatedTestId='help-card-with-not-full-menu'
                >
                    <Button
                        mode='basic'
                        onClick={() => toggleShowContactUsPopup()}
                        automatedTestId='help-card-button-contact'
                    >
                        <img src='/assets/help-icon.svg' alt='Help icon' />
                    </Button>
                </Card>
            )}

            {desktopMenuState === 'full' && (
                <Card
                    className='help-card'
                    automatedTestId='help-card-with-full-menu'
                >
                    <Container className='card-header'>
                        <img src='/assets/help-icon.svg' alt='Help icon' />
                        <Word
                            value={t('menu.help.title')}
                            textStyle='normalBold'
                            className='card-title'
                        />
                    </Container>
                    <Word
                        value={t('menu.help.desc')}
                        textStyle='normalReg'
                        className='card-desc'
                    />
                    <Button
                        mode='outline'
                        onClick={() => toggleShowContactUsPopup()}
                        automatedTestId='help-card-button-contact'
                    >
                        <Word
                            value={t('menu.help.contact')}
                            textStyle='normalBold'
                            className='help-button-text'
                        />
                    </Button>
                </Card>
            )}
            <HelpModal
                title={!formSent || loading ? t('menu.help.popup.title') : ''}
                visible={showContactUsPopup}
                toggleModal={function(): void {
                    toggleShowContactUsPopup();
                }}
                automatedTestIdDesktopContent={'help-modal-desktop-content'}
                automatedTestIdMobileContent={'help-modal-mobile-content'}
                desktopContent={displayModalContent}
                mobileContent={displayModalContent}
            />
        </>
    );
};

export const HelpCardStyles = css`
    .sds-container {
        .center-item {
            display: block;
            margin: auto;
            .sds-button-container .sds-button.plain {
                padding: 23px 97px;
            }
        }
        .modal-flex-desktop-header {
            align-items: center;
            .sds-word.titleLarge {
                font-size: 25px;
            }
        }
        .StatusContent {
            align-items: center;
            justify-content: center;
            display: flex;
            svg {
                width: 207px;
            }
        }

        .modal-flex-desktop-position {
            height: fit-content;
            width: 80%;
            max-width: 880px;
            min-width: 770px;
        }
        .help-card {
            background-color: #102b4e;
        }

        .card-header {
            flex-direction: row;
            align-items: center;
        }

        .card-title {
            color: #ffffff;
            margin-left: 10px;
        }

        .card-desc {
            color: #ffffff;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .contactus-popup {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 3rem;
            margin: 2vh;
            min-width: 36vw;
            min-height: 50vh;
            z-index: 10;
            @media (max-width: 768px) {
                min-height: 50vh;
            }
            .LoderContainer {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .contactus-popup-inputs {
                gap: 2em;
                .contactus-popup-firstrow {
                    @media (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                    }
                    flex-direction: row;
                    gap: 2em;
                    .contactus-popup-object {
                        flex: 1 1 0;
                    }
                    .contactus-popup-object {
                        .sds-text-input {
                            flex-direction: row;
                            -webkit-box-align: center;
                            align-items: center;
                            height: 55px;
                            padding: 0px 15px;
                            border-width: 1px;
                            border-style: solid;
                            border-radius: 8px;
                            background-color: rgb(255 255 255);
                            border-color: #d5d5d5;
                            margin-top: 5px;
                        }
                        .sds-text-input:focus-within {
                            background-color: ${colors.white};
                            border-color: ${(props: ThemeType) =>
                                props.theme.colors.primary};
                        }
                    }
                    .contactus-popup-motif {
                        flex: 1 1 0;
                    }
                    .contactus-popup-motif .dropdown-input {
                        position: relative;
                        background-color: rgb(255 255 255);
                        border-color: #d5d5d5;
                        -webkit-box-align: center;
                        height: 55px;
                        padding: 0px 15px;
                        border-width: 1px;
                        border-style: solid;
                        align-items: flex-start;
                        -webkit-box-pack: center;
                        justify-content: center;
                        margin-top: 5px;
                        border-radius: 8px;
                    }
                    .contactus-popup-object .sds-text-input input,
                    .contactus-popup-object .sds-text-input input::placeholder,
                    .contactus-popup-motif .dropdown-input span {
                        font-family: ${(props: ThemeType) =>
                            props.theme.texts.fontFamilyMedium};
                        color: black;
                        font-size: 12pt;
                    }
                }
                .contactus-popup-message {
                    textarea,
                    textarea::placeholder {
                        resize: none;
                        color: black;
                        font-family: ${(props: ThemeType) =>
                            props.theme.texts.fontFamilyMedium};
                        font-size: 12pt;
                    }
                    .sds-textarea-input {
                        background-color: rgb(255 255 255);
                        border-color: #d5d5d5;
                        border-radius: 22px;
                    }
                    .sds-textarea-input:focus-within {
                        background-color: ${colors.white};
                        border-color: ${(props: ThemeType) =>
                            props.theme.colors.primary};
                    }
                }
            }
            .contactus-popup-filepicker {
                .sds-button-container .sds-button.plain {
                    background-color: ${(props: ThemeType) =>
                        props.theme.colors.primary};
                }
            }
        }
        .contactus-popup-footer {
            flex-direction: row;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .sds-button-container .sds-button.plain {
                padding: 22px 70px;
            }
        }
        .contactus-popup-background {
            position: absolute;
            bottom: -37%;
            width: 105%;
            margin-left: -10%;
            z-index: -1;
        }
        .filePickerErrorMessage .sds-word {
            color: #fe3123;
            margin: 2px 0 5px 5px;
        }
    }
`;

export default HelpCard;
