import { FC } from 'react';
import { css } from 'styled-components';
//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import { ThemeType } from 'saur-design-sys-web/lib/BrandsConfig';
import { RouteTypes, usePath } from '../../../common/navigation';
import ExternalAppsIcon from 'saur-design-sys-web/lib/Icons/ApplicationsIcon';
import { Claim } from 'saur-viseau-state-management/lib/settings/state';

//Internal
import { useTranslation } from 'react-i18next';
import Accordion from '../../Accordion';
import CustomLink from '../../CustomLink';
import useExternalApplications from '../hooks/useExternalApplications';

type IProps = {
    claims: Claim[]
};

const SecondaryMenuItem: FC<IProps> = ({claims}) => {
    const { getPath } = usePath();
    const {
        getExternApplications,
        openExternalApp
    } = useExternalApplications();
    const { t } = useTranslation();

    let appEnv = (process.env.REACT_APP_ENV || "").trim()
    const isProduction =  appEnv !== "rec" && appEnv !== "dev" && appEnv !== "test";
    const externApplications = getExternApplications();

    const claimsConfig: any = {
        "Fiche Client": "ACCESS_FICHE_CLIENT",
        "Fiche Client V2": "ACCESS_FICHE_CLIENT",
    }
    
    const localFF: any = {
        "Fiche Client V2": false,
    }


    return (
        <>
            {externApplications?.length > 0 && (
                <>
                    <Container className='hr' />
                    <Accordion
                        leftIcon={<ExternalAppsIcon />}
                        title={t('menu.externalApps')}
                    >
                        <Container className='secondary-menu-item-container'>   
                            {
                                externApplications
                                .filter(({name}) => !claimsConfig[name] || !!claims.some(({ code }) => code === claimsConfig[name]))
                                .filter(({name}) => !localFF.hasOwnProperty(name) || localFF[name])
                                .map(({ name, redirectUrl }) => (
                                    <CustomLink
                                        key={name}
                                        automatedTestId={name}
                                        to={redirectUrl}
                                        isExternalLink={true}
                                        onClick={() => openExternalApp(name)}
                                    >
                                        <Word
                                            value={name}
                                            textStyle='normalMed'
                                            className='menu-text'
                                        />
                                    </CustomLink>
                                ))
                            }
                            { !isProduction && 
                                externApplications.find(({name}) => name === "Fiche Client V2") &&
                                claims.find(({ code }) => code === "ACCESS_FICHE_CLIENT") &&
                                    <CustomLink
                                        automatedTestId={"fiche-client-v2"}
                                        to={getPath(RouteTypes.FICHE_CLIENT)}
                                        isExternalLink={false}
                                    >
                                        <Word
                                            value={"Fiche Client V2"}
                                            textStyle='normalMed'
                                            className='menu-text'
                                        />
                                    </CustomLink>
                            }
                        </Container>
                    </Accordion>
                </>
            )}
            
        </>
    );
};
export default SecondaryMenuItem;

//Style
const AccordionStyles = css`
    .accordion-container {
        margin-top: 14px;
        padding: 8px 12px;
        width: auto;

        .accordion-header {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: start;
            justify-content: start;
            align-items: center;

            cursor: pointer;
            .accordion-header_Left-icon {
                height: 30px;
                width: 30px;
                justify-content: center;
                align-items: center;
                svg path {
                    fill: ${(props: ThemeType) => props.theme.texts.menuText};
                }
            }
            .accordion-header_title {
                color: ${(props: ThemeType) => props.theme.texts.menuText};
            }
            .accordion-header_arrow-icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 0.8rem;
                transform: rotate(0deg);
                transition: transform 0.7s;
                svg path {
                    fill: ${(props: ThemeType) => props.theme.texts.menuText};
                }
                &.open {
                    transform: rotate(180deg);
                }
            }
        }
        .accordion-content {
            padding: 1rem;
            padding-top: 0;
            transition: all 0.7s;
            display: block;
            margin-top: 1rem;
        }
    }
`;

export const SecondaryMenuItemStyles = css`
    .secondary-menu-item-container {
        flex-direction: column;
        margin-left: 18px;
    }
    ${AccordionStyles}
`;
