import React, { FC, useState } from 'react';
import i18next from 'i18next';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//CPOSM
import Community from 'saur-viseau-state-management/lib/common/models/Community';
import { States as ContractStates } from 'saur-viseau-state-management/lib';
import { getCommunityList } from 'saur-viseau-state-management/lib/contracts/selectors';

//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import { ThemeType } from 'saur-design-sys-web/lib/BrandsConfig';

//INTERNAl
import TestIds from '../../automated-tests-ids/ids';
import CommunitySelector, {
    CommunitySelectorStyles
} from './CommunitySelector';
import { usePath, RouteTypes } from '../../common/navigation';

interface IProps {
    lang: string;
    token: string;
    displayAdminPanel: () => void;
}

const CommunityChangeModal: FC<IProps> = ({
    lang,
    token,
    displayAdminPanel
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const history = useHistory();
    const { getPath } = usePath();

    const communitiesList = useSelector((state: ContractStates) =>
        getCommunityList(state)
    ) as Community[];

    const handleOnMenuClicked = () => {
        //setIsVisible(true)
        //redirect to dashboard
        history.push({
            pathname: `/${lang}${getPath(RouteTypes.COMMUNITY_SELECTION)}`
        });
    };

    const displayAdministration = () => {
        setIsVisible(false);
        displayAdminPanel();
    };

    const setSelectedCommunity = () => {
        //close panel
        setIsVisible(false);
         //redirect to dashboard
         history.push({
            pathname: `/${lang}${getPath(RouteTypes.DASHBOARD)}`
        });
        window.location.reload()
    };

    //Conditionner l'affichage du bouton "changer de collectivité" en fonction du nbre de collectivités remontées par l'api (ne l'afficher que si > 1)
    if (!communitiesList || !(communitiesList.length > 1)) return null;

    return (
        <>
            <Container
                onClick={handleOnMenuClicked}
                className='CommunityChangeModal__menuItem'
                automatedTestId={TestIds.menu.openCollectivityListModal}
            >
                {i18next.t('menu.changeCommunity.subMenu')}
            </Container>
            {isVisible && (
                <CommunitySelector
                    {...{
                        setIsVisible,
                        displayAdministration,
                        communitiesList,
                        setSelectedCommunity
                    }}
                />
            )}
        </>
    );
};

export const CommunityChangeStyles = css`
    .sds-container {
        .CommunityChangeModal__menuItem {
            padding: 10px 0;
            cursor: pointer;
            color: ${(props: ThemeType) => props.theme.texts.menuText};
            font-size: 15px;
            font-family: montserrat-medium;
            line-height: 22px;
        }
        ${CommunitySelectorStyles}
    }
`;

export default CommunityChangeModal;
