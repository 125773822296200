import { FC, useEffect, useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { css } from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import { device } from 'saur-design-sys-web/lib/Theme';

//Internal
import useRequestScript from '../common/useRequestScript';
import { DesktopMenuState, ModalMode} from '../common/customTypes';
import useEventListener from '../common/useEventListener';
import TopMenuContent, { TopMenuStyles } from './AppMenu/TopMenuContent';
import { getAuthProvider } from '../common/authProvider';
import BottomMenuContent, {
    BottomMenuContentStyles
} from './AppMenu/BottomMenuContent';

type AppMenuProps = {
    toggleMenu: (option: ModalMode) => void;
    menuStatus: ModalMode;
    desktopMenuState?: DesktopMenuState;
    collapseMenu: (style: DesktopMenuState) => void;
};

const AppMenu: FC<AppMenuProps> = ({
    toggleMenu,
    menuStatus,
    desktopMenuState = 'full',
    collapseMenu
}) => {
    useRequestScript(['administration-widget']);
    const location = useLocation();
    const history = useHistory();
    const keycloak = getAuthProvider();

    let menuIsOpen = menuStatus === 'out' ? 'menu-is-open' : 'menu-is-closed';
    let appMenuClassName = `app-menu ${menuIsOpen}`;

    const [
        visibleAdministrationPanel,
        setVisibleAdministrationPanel
    ] = useState(false);

    const displayAdminPanel = () => {
        setVisibleAdministrationPanel(true);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        if (
            urlParams.has('action') &&
            urlParams.get('action') === 'displayAdminPanel'
        ) {
            //show admin panel
            displayAdminPanel();
        }
    }, [location]);

    useEventListener('administration-on-close-event', (e) => {
        setVisibleAdministrationPanel(false);

        //remove action=displayAdminPanel form url
        const urlParams = new URLSearchParams(location.search);
        if (
            urlParams.has('action') &&
            urlParams.get('action') == 'displayAdminPanel'
        ) {
            const urlParams = new URLSearchParams(location.search);
            urlParams.delete('action');
            history.replace({
                search: urlParams.toString()
            });
        }
    });

    return (
        <ReactKeycloakProvider authClient={keycloak} initOptions={{}}>
            <Container className={appMenuClassName} automatedTestId='AppMenu'>
                <TopMenuContent
                    desktopMenuState={desktopMenuState}
                    toggleMenu={toggleMenu}
                    collapseMenu={collapseMenu}
                    displayAdminPanel={displayAdminPanel}
                    visibleAdministrationPanel={visibleAdministrationPanel}
                />
                <BottomMenuContent desktopMenuState={desktopMenuState} />
            </Container>
        </ReactKeycloakProvider>
    );
};

export const AppMenuStyles = css`
    .app-menu {
        min-height: 100vh;
        justify-content: space-between;
        &.menu-is-open {
            display: flex;
        }
        &.menu-is-closed {
            @media ${device.mobile} {
                display: none;
            }
            @media ${device.tablet} {
                display: flex;
            }
        }
        ${BottomMenuContentStyles}
        ${TopMenuStyles}
    }
`;

export default AppMenu;
