import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import CookiesBanner from './CookiesBanner';
//CPO SM
import {
    CookieSetting,
    settingsActions
} from 'saur-viseau-state-management/lib/settings/state';
import { getCookiesSettings } from 'saur-viseau-state-management/lib/settings/selectors';
//Internal
import CookiesMenuPanel from 'saur-design-sys-web/lib/Molecules/CookiesMenuPanel/CookiesMenuPanel';
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import ID_VALUES from '../../automated-tests-ids/ids';
import {
    getComputedData,
    listenCookieChange,
    toCookie
} from './CookiesSettingsHelper';
import { Option } from './CookiesSettingsHelper';

type Props = {
    mode: 'banner' | 'button';
};

const CookiesSettings: FC<Props> = ({ mode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //selectors
    const cookieSettings = useSelector(getCookiesSettings) as Array<
        CookieSetting
    >;

    const [showCookiesBanner, setShowCookiesBanner] = useState<boolean>(false);

    //uesState
    const [displayCookiesPanel, setDisplayCookiesPanel] = useState<boolean>(
        false
    );

    //Listner
    listenCookieChange(({ newValue }) => {
        if (newValue === undefined) {
            setShowCookiesBanner(true);
        } else {
            setShowCookiesBanner(false);
        }
    }, 1000);

    //UseEffect
    useEffect(() => {
        const cookies = new Cookies();
        const userAnalyticCookie = cookies.get('analytic');
        if (userAnalyticCookie) {
            //Mis à jours du state avec la configuration de l'utilisateur
            const cookieList: Array<CookieSetting> = [];
            let savedCookie: any;
            for (const cookie of cookieSettings) {
                savedCookie = cookies.get(cookie.rule);
                if (savedCookie) {
                    cookieList.push({
                        ...cookie,
                        accepted: savedCookie === 'true'
                    });
                }
            }
            dispatch(settingsActions.updateCookiesSetting(cookieList));
            setShowCookiesBanner(false);
        } else {
            setShowCookiesBanner(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function saveUserCookieChoice(nextState: any) {
        const cookies = new Cookies();
        const today = new Date();
        const expiresCookiesDate = new Date(
            today.setMonth(today.getMonth() + 6)
        );

        for (const cookie of nextState) {
            cookies.set(cookie.rule, cookie.accepted, {
                expires: expiresCookiesDate,
                path: '/'
            });
        }
        dispatch(settingsActions.updateCookiesSetting(nextState));
        setDisplayCookiesPanel(false);
        setShowCookiesBanner(false);
    }

    function acceptAllCookies() {
        const nextState = cookieSettings.map((cs) => ({
            ...cs,
            accepted: true
        }));

        saveUserCookieChoice(nextState);
    }

    function acceptCustomCookies(values: Array<Option>) {
        const nextState = values
            .map(toCookie)
            .filter((v) => v !== null && v.rule !== 'required') as Array<
            CookieSetting
        >;
        saveUserCookieChoice(nextState);
    }

    function rejectAllCookies() {
        const nextState = cookieSettings.map((cs) => ({
            ...cs,
            accepted: false
        }));

        saveUserCookieChoice(nextState);
    }

    return (
        <>
            <CookiesBanner
                mode={mode}
                acceptAllCookies={acceptAllCookies}
                setDisplayCookiesPanel={setDisplayCookiesPanel}
                displayCookiesPanel={displayCookiesPanel}
                showCookiesBanner={showCookiesBanner}
            />
            {displayCookiesPanel && cookieSettings && (
                <Container automatedTestId={ID_VALUES.CookiesPanel}>
                    <CookiesMenuPanel
                        OptionsTitleList={t(
                            'cookiesConfig.panel.OptionsTitleList'
                        )}
                        contentPanel={t('cookiesConfig.panel.contentPanel', {
                            weburl: 'https://cpo-online-v2.saur.fr/'
                        })}
                        titlePanel={t('cookiesConfig.panel.titlePanel')}
                        alwayActifLabel={t(
                            'cookiesConfig.panel.alwayActifLabel'
                        )}
                        rejectAllCookiesLabel={t(
                            'cookiesConfig.panel.rejectAllCookiesLabel'
                        )}
                        acceptAllCookiesLabel={t(
                            'cookiesConfig.panel.acceptAllCookiesLabel'
                        )}
                        options={getComputedData(cookieSettings, t)}
                        acceptCustomCookies={(v) => acceptCustomCookies(v)}
                        rejectAllCookies={() => rejectAllCookies()}
                        acceptAllCookies={acceptAllCookies}
                        closeMenuPanel={() => {
                            setDisplayCookiesPanel(false);
                        }}
                        policyTitle={t('cookiesConfig.panel.policyTitle')}
                        policyMessage={t('cookiesConfig.panel.policyMessage')}
                        policyLink={
                            'https://www.saurclient.fr/informations-cookies'
                        }
                    />
                </Container>
            )}
        </>
    );
};

export default CookiesSettings;
