
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import AppLogo from "saur-design-sys-web/lib/Atoms/AppLogo/AppLogo";
import {
  colors,
  onlyForDesktop,
  device,
  onlyForMobile,
  elevation,
} from "saur-design-sys-web/lib/Theme";
import { css } from "styled-components";

const BubbleHeader = () => {
  return (
    <Container className="BubbleHeader">
      <Container className="BubbleHeader-header">
        <Container className="BubbleHeader-header-column">
          <AppLogo version={1} type="lighten" content="login" />
          <Word
            className="BubbleHeader-header-title-mobile"
            value="Bienvenue sur"
            textStyle="title"
            variant="p"
          />
          <Word
            className="BubbleHeader-header-title-mobile BubbleHeader-header-title-mobile-part2"
            value="Viseau"
            textStyle="title"
            variant="p"
          />
        </Container>
      </Container>
      <Container className="BubbleHeader-header-circle" />
    </Container>
  );
};

export const BubbleHeaderStyles = css`
  .BubbleHeader {
    z-index: 0;
    position: relative;
    .BubbleHeader-header {
      z-index: ${elevation.sup.default};
      align-items: center;
      justify-content: center;

      .BubbleHeader-header-column {
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
        @media ${device.mobile} {
          width: 100%;
        }
        @media ${device.tablet} {
          transform: translateY(-125%);
          width: auto;
        }
        .logo {
          width: 85px;
          height: 86px;
        }
        .BubbleHeader-BackButtonMobile {
          ${onlyForMobile}
          position: absolute;
          left: 4%;
          top: 50%;
          transform: translateY(-50%);
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: ${colors.white};
            }
          }
        }
      }
      .BubbleHeader-header-title-desktop {
        ${onlyForDesktop}
        color: white;
        white-space: nowrap;
        font-size: 25px;
      }
      .BubbleHeader-header-title-mobile {
        ${onlyForMobile}
        color: white;
        font-size: 20px;
        margin-top: 20px;
      }
      .BubbleHeader-header-title-mobile-part2 {
        margin-top: 5px;
      }
    }
    .BubbleHeader-header-circle {
      position: absolute;
      left: 50%;
      width: 420px;
      height: 800px;
      background-color: #233876;
      border-radius: 20%;
      transform: translateY(-50%) translateX(-50%);
      z-index: -10;
    }
  }
`;

export default BubbleHeader;
