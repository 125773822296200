import { FC } from 'react';
//SDS
import CookiesBannerUI from 'saur-design-sys-web/lib/Molecules/CookiesBanner/CookiesBanner';
import Button from 'saur-design-sys-web/lib/Atoms/Input/Button/Button';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
//Internal
import ID_VALUES from '../../automated-tests-ids/ids';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
    mode: 'banner' | 'button';
    acceptAllCookies: any;
    setDisplayCookiesPanel: any;
    displayCookiesPanel: boolean;
    showCookiesBanner:boolean;
};

const CookiesBanner: FC<Props> = ({
    mode,
    acceptAllCookies,
    setDisplayCookiesPanel,
    displayCookiesPanel,
    showCookiesBanner
}) => {
    const { t } = useTranslation();
    return (
        <>
            {showCookiesBanner && mode === 'banner' && (
                <CookiesBannerUI
                    className='cookies-Banner-ui'
                    automatedTestId={ID_VALUES.CookiesBanner}
                    infoText={t('cookiesConfig.banner.infoText')}
                    toggleMenuCookies={() =>
                        setDisplayCookiesPanel(!displayCookiesPanel)
                    }
                    toggleMenuCookiesLabel={t(
                        'cookiesConfig.banner.toggleMenuPanelLabel'
                    )}
                    acceptCookies={acceptAllCookies}
                />
            )}
            {mode === 'button' && (
                <Button
                    mode='basic'
                    automatedTestId={ID_VALUES.footer.links.cookies}
                    onClick={() => {
                        setDisplayCookiesPanel(!displayCookiesPanel);
                    }}
                >
                    <Word
                        className='NavLink'
                        value={'Cookies'}
                        textStyle='tinyMedium'
                        variant='span'
                    />
                </Button>
            )}
        </>
    );
};

export const CookiesBannerStyles = css`
    .cookies-Banner-ui {
        z-index: 2;
    }
`;

export default CookiesBanner;