import { FC, MouseEvent, ReactElement } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { css } from 'styled-components';
//CPOSM
import Button from 'saur-design-sys-web/lib/Atoms/Input/Button/Button';
import { getLanguage } from 'saur-viseau-state-management/lib/settings/selectors';
//SDS
import { ThemeType } from 'saur-design-sys-web/lib/BrandsConfig';

interface IProps {
    to?: string;
    activeOnlyWhenExact?: boolean;
    children: ReactElement;
    automatedTestId?: string;
    state?: string;
    isExternalLink?: boolean;
    onClick?: (option: any) => void;
    onMouseOver?: (option: any) => void;
    onMouseOut?: (option: any) => void;
}

const CustomLink: FC<IProps> = ({
    to,
    activeOnlyWhenExact = false,
    children,
    automatedTestId = '',
    state = 'full',
    isExternalLink = false,
    onClick,
    onMouseOver,
    onMouseOut
}) => {
    const currentLang = useSelector(getLanguage) as string;

    const finalRoute = `/${currentLang}${to}`;

    const match = useRouteMatch({
        path: finalRoute,
        exact: activeOnlyWhenExact
    });
    const handleLinkClick = (e: MouseEvent) => {
        if (onClick) e.preventDefault();
    };
    const isActive = !!match;

    return (
        <Button
            mode='basic'
            onClick={onClick}
            className={`custom-link-button ${
                isActive ? 'is-active' : 'is-not-active'
            } ${state === 'full' ? 'full' : 'collapse'}`}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {!isExternalLink ? (
                <Link
                    to={finalRoute}
                    id={automatedTestId}
                    data-testid={automatedTestId}
                >
                    {children}
                </Link>
            ) : (
                <Link
                    to={{ pathname: to }}
                    id={automatedTestId}
                    data-testid={automatedTestId}
                    onClick={(e) => handleLinkClick(e)}
                >
                    {children}
                </Link>
            )}
        </Button>
    );
};

export const CustomLinkStyles = css`
    .custom-link-button {
        flex: 1;
        flex-direction: row;
        width: auto;

        &:hover {
            background-color: ${(props: ThemeType) =>
                props.theme.field.hoverColor};
            border-radius: 8px;
        }

        &.is-active {
            background-color: ${(props: ThemeType) =>
                props.theme.field.activeColor};
            border-radius: 8px;
            box-shadow: 0 14px 14px 3px rgba(0, 0, 0, 0.05);
            padding: 8px 12px;
            margin-top: 14px;
            width: auto;
            &:hover {
                background-color: ${(props: ThemeType) =>
                    props.theme.field.activeColor};
            }
        }
        &.is-not-active {
            margin-top: 14px;
            padding: 8px 12px;
            width: auto;
        }

        &.collapse {
            justify-content: center;
        }
    }
`;

export default CustomLink;
