import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { css } from 'styled-components';

//CPOSM
import Community from 'saur-viseau-state-management/lib/common/models/Community';

//SDS
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import LeftArrow from 'saur-design-sys-web/lib/Icons/LeftArrow';
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import { elevation } from 'saur-design-sys-web/lib/Theme';
import { device } from 'saur-design-sys-web/lib/Theme';

//INTERNAl
import CommunitiesList, { CommunitiesListStyles } from './CommunitiesList';
import TestIds from '../../automated-tests-ids/ids';
import { sortBy } from '../../common/utils';

interface IProps {
    showGobackBtn?: boolean;
    communitiesList: Community[];
    showLoadingError?: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    displayAdministration: () => void;
    setSelectedCommunity: () => void;
}

const CommunitySelector = ({
    showGobackBtn = true,
    communitiesList,
    showLoadingError = false,
    setIsVisible,
    displayAdministration,
    setSelectedCommunity
}: IProps) => {
    const [sortedCommunities, setSortedCommunities] = useState(communitiesList)
    useEffect(() => {
        setSortedCommunities(sortBy(communitiesList, "longName", 1))
    },[communitiesList])
    return (
        <Container className='CommunityChangeModal_Container'>
            <Container className='CommunityChangeModal_Content'>
                <Container className='CommunityChangeModal_Header'>
                    {showGobackBtn && (
                        <Container
                            automatedTestId={
                                TestIds.menu.closeCollectivityListModal
                            }
                            className='CommunityChangeModal_closingButton'
                            onClick={() => setIsVisible(false)}
                        >
                            <LeftArrow />
                        </Container>
                    )}
                    <Word
                        className='CommunityChangeModal_Title'
                        textStyle='title'
                        value={i18next.t('menu.changeCommunity.modal.title')}
                    />
                </Container>

                <CommunitiesList
                    displayAdministration={displayAdministration}
                    communitiesList={sortedCommunities}
                    setSelectedCommunity={setSelectedCommunity}
                    showLoadingError={showLoadingError}
                />
            </Container>
        </Container>
    );
};

export default CommunitySelector;

export const CommunitySelectorStyles = css`
    .CommunityChangeModal_Container {
        position: fixed;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${elevation.high.top};
        background: #f5f8fb;
        padding: 24px;
        overflow-y: scroll;
        .CommunityChangeModal_Content {
            margin: auto;
            @media ${device.mobile} {
                max-width: 100%;
            }
            @media ${device.tablet} {
                max-width: 70%;
            }
        }
    }

    .CommunityChangeModal_Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 32px;
        .CommunityChangeModal_Title {
            text-transform: uppercase;
        }
        .CommunityChangeModal_closingButton {
            cursor: pointer;
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-right: 20px;
                width: 26px;
                height: 26px;
                &:hover .leftArrow-path {
                    fill: gray;
                }
            }
        }
    }
    ${CommunitiesListStyles}
`;
