import React, { useEffect, useState } from 'react';

//CPOSM
import DoubleArrowLeftIcon from "saur-design-sys-web/lib/Icons/DoubleArrowLeftIcon";
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import { css } from 'styled-components';
import DropDownList from "saur-design-sys-web/lib/Molecules/DropDownList/DropDownList";
import DoubleArrowRightIcon from "saur-design-sys-web/lib/Icons/DoubleArrowRightIcon";
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';

const Pagination = ({list, setPageList,nextLabel, onLabel,previousLabel}: any) => {
    const maxPage = 20
	const [page, setPage] = useState(0);
	const [selectedPageOption, setSelectedPageOption] = useState({ id: page, label: page + 1 + "", value: page });
	const [totalPages, setTotalPages] = useState(Math.ceil((list?.length || 0) / maxPage) || 1);

	const goToPage = (nextPage: number) => {
		if (nextPage >=0 && nextPage < totalPages && nextPage !== page) {
			setPage(nextPage);
		}
	};
	
	useEffect(() => {
		setTotalPages(Math.ceil((list?.length || 0) / maxPage) || 1)
	},[list])


	useEffect(() => {
		setPageList(list?.slice(page * maxPage, page * maxPage + maxPage));
		setSelectedPageOption({ id: page, label: page + 1 + "", value: page })
	}, [page, list ]);

	useEffect(() => {
		if(page > 0 && list && list.length > 0){
			goToPage(0);
		}
    },[list])

	const handleOptionChange = (option: any) => {
		if(option.value != page){
			goToPage(option.value);
		}
	}

    return (
        <Container className="pagination-container ">
            <Container
                className="pagination-arrow"
                onClick={() => goToPage(page - 1)}
            >
                <DoubleArrowLeftIcon />
                <Word value={previousLabel || ""} textStyle="normalBold" />
            </Container>
            <Container>
                <Container >
                    <DropDownList
                        values={Array.from(Array(totalPages).keys()).map((index) => {
                            return { id: index, label: index + 1 + "", value: index };
                        })}
                        selectedValue={selectedPageOption}
                        onChange={handleOptionChange}
                    />
                </Container>
                <Container className="pagination-dinglePage">
                    <Word value={onLabel + ` ${totalPages}`} textStyle="tinyRegular" />
                </Container>
            </Container>
            <Container
                className="pagination-arrow"
                onClick={() => goToPage(page + 1)}
            >
                <Word value={nextLabel || ""} textStyle="normalBold" />
                <DoubleArrowRightIcon />
            </Container>
        </Container>
    );
};

export default Pagination;


export const PaginationStyles = css`

	.pagination-container {
		justify-content: center;
		margin: 20px;
		.pagination-arrow {
			cursor: pointer;
			margin: 0 15px;
			svg {
				margin: 0 7px;
			}
		}
		&.sds-container,
		.sds-container {
			flex-direction: row;
			align-items: center;
		}
		.pagination-dinglePage {
			margin: 10px 0 10px 10px;
		}
		.sds-container {
			.dropdown-input {
				background: none;
				height: 30px;
				border: 1px solid #200000;
				border-radius: 8px;
				width: 27px;
				padding: 0px 11px;
				justify-content: start;
				.dropdown-icon-container {
					right: 12px;
					width: 9px;
					height: 9px;
				}
			}
			.dropdown-list {
				min-width: 55px;
				.dropdown-list-item {
					cursor: pointer;
					&.selected,
					&:hover {
						background: white;
						.sds-word {
							color: rgb(35, 35, 35);
						}
					}
				}
			}
		}
	}
`;
