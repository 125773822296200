//EXTERNAL
import { FC } from 'react';
import { css } from 'styled-components';
//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import AppLogo from 'saur-design-sys-web/lib/Atoms/AppLogo/AppLogo';
import { device, colors } from 'saur-design-sys-web/lib/Theme';

//CPO SM
import { States } from 'saur-viseau-state-management/lib';
import { isFeatureEnabled } from 'saur-viseau-state-management/lib/settings/selectors';

// INTERNAL
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ID_VALUES from '../automated-tests-ids/ids';
import CookiesSettings from '../components/Cookies/CookiesSettings';

const AppFooter: FC = () => {
    const settingState = useSelector((state: States) => state.settings);
    const { t } = useTranslation();
    const brandName = settingState?.params?.BrandFullName ?? 'Saur';
    const displayFooterCookiesButton = useSelector((state: States) =>
        isFeatureEnabled(state, 'Show-Cookies-Banner')
    );
    const logoName = settingState?.params?.LogoName || "saur"
    return (
        <Container
            id={ID_VALUES.footer.footer}
            className='app-footer'
            automatedTestId={ID_VALUES.footer.footer}
        >
            <Container className='main-footer-container'>
                <AppLogo forceBrand={logoName} version={2} content='footer' />
                <Container className='footer-nav-container'>
                    {displayFooterCookiesButton && (
                        <CookiesSettings mode='button' />
                    )}
                </Container>
                <Container>
                    <Word
                        value={t('cookiesConfig.banner.copyRight', {
                            brandName: brandName
                        })}
                        textStyle='xxsRegular'
                        variant='span'
                    />
                </Container>
            </Container>
        </Container>
    );
};

export const AppFooterStyles = css`
    .app-footer {
        @media ${device.mobile} {
            height: auto;
        }
        @media ${device.tablet} {
            height: 56px;
        }
        .main-footer-container {
            align-items: center;
            justify-content: space-between;
            background-color: ${colors.white};
            padding: 0 6%;
            border-top-right-radius: 30px;
            @media ${device.mobile} {
                height: auto;
                max-height: 150px;
                flex-direction: column;
                padding: 12px 0 25px 0;
                margin-bottom: 40px;
                margin-top: 10px;
                align-items: center;
            }
            @media ${device.tablet} {
                height: 56px;
                flex-direction: row;
                padding: 0 20px;
            }
        }
        .footer-nav-container {
            .sds-button {
                width: auto;
            }

            @media ${device.mobile} {
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                .sds-button-container {
                    margin-right: 0;
                }
            }
            @media ${device.tablet} {
                flex-direction: row;
                margin-top: 0px;
                .sds-button-container {
                    margin-right: 30px;
                }
            }
        }
    }
`;

export default AppFooter;
