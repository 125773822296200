import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from 'styled-components';
import i18next from 'i18next';

//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import { Brands, ThemeType } from 'saur-design-sys-web/lib/BrandsConfig';
//CPOSM
import { States } from 'saur-viseau-state-management/lib';
import {
    getLanguage,
    getUserClaims,
    isFeatureEnabled
} from 'saur-viseau-state-management/lib/settings/selectors';
//Internal
import { DesktopMenuState, ModalMode} from '../../common/customTypes';
import { RouteTypes } from '../../common/navigation';
import CommunityChangeModal from '../CommunityChange';
import LogoContainer, { LogoContainerStyles } from './TopMenu/LogoContainer';
import LogoutMenuSection, {
    LogoutMenuSectionStyles
} from './TopMenu/LogoutMenuSection';
import PrimaryMenuItem, {
    PrimaryMenuItemStyles
} from './TopMenu/PrimaryMenuItem';
import ProfileContent, { ProfileContentStyles } from './TopMenu/ProfileContent';
import SecondaryMenuItem, {
    SecondaryMenuItemStyles
} from './TopMenu/SecondaryMenuItem';

type TopMenuProps = {
    desktopMenuState: string;
    toggleMenu: (option: ModalMode) => void;
    collapseMenu: (style: DesktopMenuState) => void;
    displayAdminPanel: () => void;
    visibleAdministrationPanel: boolean;
};

const TopMenuContent: FC<TopMenuProps> = ({
    desktopMenuState,
    toggleMenu,
    collapseMenu,
    displayAdminPanel,
    visibleAdministrationPanel
}) => {
    const [routeOver, setRouteOver] = useState<string>();
    
    const theme = useSelector((s: States) => s.settings.theme) as Brands;
    const claims = useSelector((state: States) =>
        getUserClaims(state)
    ) as any[];
    const currentLang = useSelector(getLanguage) as string;

    const token = JSON.parse(localStorage.getItem('sso-response') ?? 'null')
        ?.access_token;

    const handleMouseOver = (itemType: string) => {
        setRouteOver(itemType);
    };

    const showAdminUsers = useSelector((state) =>
        isFeatureEnabled(state, 'Admin-Users')
    );
    const handleClickMenu = (itemType: RouteTypes) => {
        if (
            itemType === RouteTypes.CARTOGRAPHY ||
            itemType === RouteTypes.WATER_OBSERVATORY
        ) {
            collapseMenu('collapse');
        }
        toggleMenu('in');
        setRouteOver('');
    };

    return (
        <Container
            className={`top-content ${
                desktopMenuState === 'full' ? 'full' : 'collapse'
            }`}
        >
            <LogoContainer
                desktopMenuState={desktopMenuState}
                toggleMenu={toggleMenu}
                collapseMenu={collapseMenu}
            />

            <ProfileContent desktopMenuState={desktopMenuState} />

            {/* Menu Changer de collectivité */}
            {desktopMenuState === 'full' && (
                <Container>
                    <CommunityChangeModal
                        lang={currentLang}
                        token={token}
                        displayAdminPanel={displayAdminPanel}
                    />
                </Container>
            )}

            {/* Administration widget */}
            {desktopMenuState === 'full' && (
                <Container>
                    {claims &&
                        !!claims.some(({ code }) => code === 'SHOW_ADMIN_EC') && (
                            <administration-widget
                                lang={currentLang}
                                theme={theme}
                                area='community'
                                runtime='full'
                                token={token}
                                buttonname={i18next.t('administration.administrationComun')}
                                visiblefromcommunitieslist={
                                    visibleAdministrationPanel
                                }
                            />
                        )}
                </Container>
            )}

            {/* Administration site widget */}
            {!visibleAdministrationPanel && showAdminUsers && desktopMenuState === 'full' && (
                <Container>
                    {claims &&
                        !!claims.some(({ code }) => code === 'SHOW_ADMIN') && (
                            <administration-widget
                                lang={currentLang}
                                theme={theme}
                                area='site'
                                runtime='full'
                                buttonname={i18next.t('administration.administrationwebsite')}
                                token={token}
                                visiblefromcommunitieslist={false}
                            />
                        )}
                </Container>
            )}

            {/* Menu items */}
            {desktopMenuState === 'full' && <Container className='hr' />}
            <PrimaryMenuItem
                claims={claims}
                handleClickMenu={handleClickMenu}
                desktopMenuState={desktopMenuState}
                handleMouseOver={handleMouseOver}
                routeOver={routeOver}
            />

            {desktopMenuState === 'full' && <SecondaryMenuItem claims={claims} />}
            {/* LOGOUT */}
            {desktopMenuState === 'full' && <Container className='hr' />}
            <LogoutMenuSection desktopMenuState={desktopMenuState} />
        </Container>
    );
};

export const TopMenuStyles = css`
    .top-content {
        padding-top: 20px;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;

        &.collapse {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .AdministrationWidgetButton {
        padding: 10px 0;
        cursor: pointer;
        font-size: 15px;
        font-family: montserrat-medium;
        line-height: 22px;
        color: ${(props: ThemeType) => props.theme.texts.menuText};
    }
    .hr {
        height: 1px;
        background-color: ${(props: ThemeType) => props.theme.separator};
        margin-top: 18px;
    }

    .icon {
        width: 30px;
        height: 30px;
        &.collapse {
        }
        &.full {
            margin-right: 22px;
        }
    }

    .menu-text {
        color: ${(props: ThemeType) => props.theme.texts.menuText};
    }

    ${ProfileContentStyles}
    ${LogoutMenuSectionStyles}
    ${LogoContainerStyles}
    ${PrimaryMenuItemStyles}
    ${SecondaryMenuItemStyles}
`;

export default TopMenuContent;
