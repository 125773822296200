import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ExternalApplication,
    TypeIdentificationEnum
} from 'saur-viseau-state-management/lib/settings/models/ExternalApplication';
import { getExternalApplications } from 'saur-viseau-state-management/lib/settings/selectors';
import { getFicheClientKeyService } from 'saur-viseau-state-management/lib/settings/services';

type ExternalAppMenuItem = ExternalApplication & {
    redirectUrl: string;
};

/**
 * Cleans up a URL string by replacing multiple forward slashes with a single forward slash.
 *
 * @param {string} url - The URL string to be cleaned up.
 * @returns {string} The cleaned up version of the URL string.
 */
const cleanUrl = (url: string) => url.replace(/([^:])(\/\/+)/g, '$1/');

/**
 * Opens a new tab in the browser and navigates to the specified URL.
 *
 * @param url - The URL to open in the new tab.
 */
function openNewTabWithUrl(url: string): void {
    // Open a new tab in the browser and navigate to the specified URL
    const newTab: Window | null = window.open(url, '_blank');
    if (newTab) {
        newTab.focus();
    }
}

/**
 * Adds the bearer token as in query params named authorization
 *
 * @param {string} url - The absolute URL to load in the new tab.
 * @param {string} token - The bearer token to include in the authorization header.
 */
const addTokenInQueryParam = (url: string, token:string)=> `${url}?authorization=${token}`;
 
interface IParams {
    [key: string]: string;
  }


/**
 * Opens a new browser tab after setting and submit a new form
 *
 * @param {string} url - The URL to open in the new tab.
 * @param {string} account - The user account for which the login form is being set.
 * @param {string} password - The password associated with the provided account.
 * @returns {void}
 */
function openTabWithForm(
    winURL: string,
    account: string,
    password: string
) {
    const params: IParams = {
            username : account,
            password : password,
            enter : 'Logon',
            login: 'home.xml'
        }

    const winName = 'eGeremi';
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", winURL);
    for (let i in params) {
        if (params.hasOwnProperty(i)) {
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
        }
    }        
    window.open('', winName);
    form.target = winName;
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

/**
 *  Hook to handle external apps to be shown on the secondary menu
 * @returns
 */
const useExternalApplications = () => {

    const externalAppsState = useSelector(getExternalApplications);
    const [extAppMenuItems, setExtAppMenuItems] = useState(
        [] as ExternalAppMenuItem[]
    );

    const accessToken = JSON.parse(
        localStorage.getItem('sso-response') ?? 'null'
    )?.access_token;

    useEffect(() => {
        let enabledApps;

        if (externalAppsState?.success) {
            enabledApps = externalAppsState.data?.map((eApp) => {
                const { typeIdentification, baseUrl, account } = eApp;
                let redirectUrl: ExternalAppMenuItem['redirectUrl'];

                switch (typeIdentification) {
                    case TypeIdentificationEnum.URL_WITH_ACCOUNT: // concat with account
                        redirectUrl = baseUrl + '/' + account + '/';
                        break;
                    case TypeIdentificationEnum.URL_ONLY:
                    case TypeIdentificationEnum.URL_WITH_ACCOUNT_PWD:
                    default:
                        redirectUrl = baseUrl;
                }

                return { ...eApp, redirectUrl: cleanUrl(redirectUrl) };
            });
        }

        setExtAppMenuItems(enabledApps ?? []);
    }, [externalAppsState?.success, accessToken]);

    const getExternApplications = () => extAppMenuItems;

    const openExternalApp = useCallback(
        async (appNameToOpen: ExternalAppMenuItem['name']) => {
            // find the external app
            const {
                useJWT,
                redirectUrl,
                typeIdentification,
                account,
                password
            }: ExternalAppMenuItem =
                extAppMenuItems?.find(({ name }) => appNameToOpen == name) ??
                ({} as ExternalAppMenuItem);

            // if flag useJWT = TRUE, add authorization query param
            let appUrl = useJWT
                ? addTokenInQueryParam(redirectUrl, accessToken)
                : redirectUrl;

            // case : need to create a login form to submit using a username and pawword
            if (
                typeIdentification ===
                TypeIdentificationEnum.URL_WITH_ACCOUNT_PWD
            ) {               
                openTabWithForm(appUrl, account, password);
                return;
            }
            if(appNameToOpen === 'Fiche Client'){
                const key = await getFicheClientKeyService(accessToken)
                appUrl += key
            }
            // other cases: open URL in a new tab
            openNewTabWithUrl(appUrl);
        },
        [extAppMenuItems, accessToken]
    );

    return {
        getExternApplications,
        openExternalApp
    };
};

export default useExternalApplications;
