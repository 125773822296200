const BurgerMenuIcon = () => (
<svg width="24" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg"> 
    <g id="UI_Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Mobile_UI_Dashboard_Eau" transform="translate(-37.000000, -82.000000)" fill="#000000">
            <g id="Top" transform="translate(16.000000, 60.000000)">
                <g id="Menu-" transform="translate(21.000000, 22.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="3" rx="1.5"></rect>
                    <rect id="Rectangle" x="0" y="9" width="24" height="3" rx="1.5"></rect>
                    <rect id="Rectangle" x="0" y="18" width="24" height="3" rx="1.5"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default BurgerMenuIcon