/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useEffect,
    useState,
    FC,
    ReactElement,
    lazy,
    Suspense,
    useCallback,
    useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Switch,
    Route,
    Redirect,
    useParams,
    useLocation
} from 'react-router-dom';
import { css } from 'styled-components';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Cookies from 'universal-cookie';

//CPOSM
import { States } from 'saur-states-management';
import { States as SettingsStates } from 'saur-viseau-state-management/lib';
import {
    Claim,
    ClaimType,
    settingsActions
} from 'saur-viseau-state-management/lib/settings/state';
import {
    getLanguage,
    getUserClaims,
    isFeatureEnabled,
    getCookiesSettings
} from 'saur-viseau-state-management/lib/settings/selectors';

//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Loader from 'saur-design-sys-web/lib/Atoms/Loader/Loader';
import { States as ProfileStates } from 'saur-viseau-state-management/lib';
import { getUserProfileData } from 'saur-viseau-state-management/lib/user-profile/selectors';

//internal
import ContentLoggedWrapper from '../templates/ContentLoggedWrapper';
import LoginSsoScreen from '../screens/LoginSsoScreen';
import { useExpireToken } from '../common/useExpireToken';
import { RouteTypes, usePath } from '../common/navigation';
import CookiesSettings from './Cookies/CookiesSettings';
import { getMatomoName, trackMatomoEvent } from './MatomoHelper';
import { langTypes } from 'saur-states-management/lib/i18n';
import UserProfileData from 'saur-viseau-state-management/lib/user-profile/models/ProfileUserInformation';

//Lazy loaded
const LoginScreen = lazy(() => import('../screens/LoginScreen'));
const MobileLoginScreen = lazy(() => import('../screens/MobileLoginScreen'));
const DashboardScreen = lazy(() => import('../screens/DashboardScreen'));
const CartographyScreen = lazy(() => import('../screens/CartographyScreen'));
const ReportingsScreen = lazy(() => import('../screens/ReportingsScreen'));
const DocumentsScreen = lazy(() => import('../screens/DocumentsScreen'));
const NewsScreen = lazy(() => import('../screens/NewsScreen'));
const ProfileScreen = lazy(() => import('../screens/ProfileScreen'));
const RequestsScreen = lazy(() => import('../screens/RequestsScreen'));
const FicheClientScreen = lazy(() => import('../screens/FicheClientScreen'));
const CommunitySelectionScreen = lazy(() =>
    import('../screens/CommunitySelectionScreen')
);
const WaterObservatoryScreen = lazy(() =>
    import('../screens/WaterObservatoryScreen')
);

const SUPPORTED_LANGUAGES = ['fr_FR', 'en_US'];

export const CheckLang: FC<{ children: ReactElement }> = ({ children }) => {
    const params = useParams<{ lang: string }>();
    const dispatch = useDispatch();
    const settingState = useSelector((state: States) => state.settings);
    const { trackPageView, trackEvent } = useMatomo();
    const history = useLocation();
    const prevHistoryPath = usePrevious(history.pathname);
    const cookiesSettings = useSelector(getCookiesSettings);
    const currentLang = useSelector(getLanguage) as langTypes;
    const { userType } = useSelector((state: ProfileStates) => getUserProfileData(state) as UserProfileData) || {};
    const [trackedEvents, setTrackedEvents] = useState<string[]>([])
    const paramsLang = params.lang;
    const clientArea = 'Viseau';

    if (settingState.params) {
        document.title = `${settingState.params.BrandFullName} - ${clientArea}`;
    }

    function setHtmlLang(lang: string): void {
        let htmlElement = document.querySelector('html');
        if (htmlElement) {
            // @ts-ignore
            htmlElement.setAttribute("lang" , lang.split('_')[0]); //take the first part as ISO 639-1 language code
        }
    }

    function usePrevious(value: any) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }

    useEffect(() => {
        const isAnalyticsAgree = (): boolean => {
            const cookies = new Cookies();
            const userAnalyticCookie = cookies.get('analytic');
            if (userAnalyticCookie !== undefined) {
                return userAnalyticCookie === 'true';
            }
            return true;
        };

        if (
            isAnalyticsAgree() &&
            (process.env.NODE_ENV === 'production' ||
                process.env.NODE_ENV === 'test') &&
            history.pathname !== prevHistoryPath
        ) {
            trackPageView({
                documentTitle: getMatomoName(history.pathname , currentLang )
            });
                trackMatomoEvent(trackEvent, trackedEvents, setTrackedEvents, history.pathname , currentLang, userType )
        }
    }, [cookiesSettings, trackPageView, history.pathname, userType]);

    useEffect(() => {
        if (
            !!dispatch &&
            paramsLang &&
            SUPPORTED_LANGUAGES.includes(paramsLang)
        ) {
            dispatch(settingsActions.setLanguage(paramsLang));
            setHtmlLang(paramsLang);
        }
    }, [paramsLang, dispatch]);

    return children;
};

const LoaderScreen = () => (
    <Container className='LoaderScreen'>
        <Loader className='LoaderScreenLoader' />
    </Container>
);

export const LoaderScreenStyles = css`
    .LoaderScreen {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
        .LoaderScreenLoader {
            top: 50%;
            left: 50%;
            position: fixed;
            transform: translateX(-50%) translateY(-50%);
        }
    }
`;

const Navigation = () => {
    const { getPath } = usePath();
    const currentLang = useSelector(getLanguage) as string; 
    const claims = useSelector((state: SettingsStates) =>
        getUserClaims(state)
    ) as Claim[];
    const ShowNewsMenu = useSelector((state: SettingsStates) =>
        isFeatureEnabled(state, 'Show-News-Menu')
    );
    const displayCookiesBanner = useSelector((state: SettingsStates) =>
        isFeatureEnabled(state, 'Show-Cookies-Banner')
    );
    const renderRouteContent = useCallback(
        (
            element: React.ReactElement,
            isLogged: boolean,
            displayCookiesBanner,
            displayHeader: boolean = true,
            displayAppMenu: boolean = true,
            displayFooter: boolean = true
        ) => {
            return (
                <CheckLang>
                    <>
                        {isLogged ? (
                            <ContentLoggedWrapper
                                displayHeader={displayHeader}
                                displayAppMenu={displayAppMenu}
                                displayFooter={displayFooter}
                            >
                                {element}
                            </ContentLoggedWrapper>
                        ) : (
                            element
                        )}
                        {displayCookiesBanner && (
                            <CookiesSettings mode={'banner'} />
                        )}
                    </>
                </CheckLang>
            );
        },
        []
    );

    return (
        <Suspense fallback={<LoaderScreen />}>
            <Switch>
                <Route exact path={`/:lang${getPath(RouteTypes.MOBILELOGIN)}`}>
                    <MobileLoginScreen />
                </Route>
                <Route exact path={`/:lang${getPath(RouteTypes.LOGIN)}`}>
                    {renderRouteContent(
                        <LoginScreen />,
                        false,
                        displayCookiesBanner
                    )}
                </Route>
                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.CARTOGRAPHY)}`}
                    claim={claims.some(
                        (claim) => claim.code === ClaimType.SHOW_MAP
                    )}
                >
                    {renderRouteContent(
                        <CartographyScreen />,
                        true,
                        displayCookiesBanner,
                        false
                    )}
                </PrivateRoute>

                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.COMMUNITY_SELECTION)}`}
                >
                    {renderRouteContent(
                        <CommunitySelectionScreen />,
                        true,
                        false,
                        false,
                        false,
                        false
                    )}
                </PrivateRoute>

                <PrivateRoute
                    exact
                    path={`/:lang${
                        ShowNewsMenu
                            ? getPath(RouteTypes.NEWS)
                            : getPath(RouteTypes.DASHBOARD)
                    }`}
                >
                    {ShowNewsMenu
                        ? renderRouteContent(
                              <NewsScreen />,
                              true,
                              displayCookiesBanner
                          )
                        : renderRouteContent(
                              <DashboardScreen />,
                              true,
                              displayCookiesBanner,
                              true
                          )}
                </PrivateRoute>
                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.DOCUMENTS)}`}
                    claim={claims.some(
                        (claim) => claim.code === ClaimType.SHOW_DOCUMENT
                    )}
                >
                    {renderRouteContent(
                        <DocumentsScreen />,
                        true,
                        displayCookiesBanner
                    )}
                </PrivateRoute>
                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.REPORTINGS)}`}
                    claim={claims.some(
                        (claim) => claim.code === ClaimType.SHOW_REPORT
                    )}
                >
                    {renderRouteContent(
                        <ReportingsScreen />,
                        true,
                        displayCookiesBanner
                    )}
                </PrivateRoute>
                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.PROFILE)}`}
                >
                    {renderRouteContent(
                        <ProfileScreen />,
                        true,
                        displayCookiesBanner
                    )}
                </PrivateRoute>
                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.WATER_OBSERVATORY)}`}
                >
                    {renderRouteContent(
                        <WaterObservatoryScreen />,
                        true,
                        displayCookiesBanner,
                        true
                    )}
                </PrivateRoute>
                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.REQUESTS)}`}
                >
                    {renderRouteContent(
                        <RequestsScreen />,
                        true,
                        displayCookiesBanner,
                        true
                    )}
                </PrivateRoute>
                <PrivateRoute
                    exact
                    path={`/:lang${getPath(RouteTypes.FICHE_CLIENT)}`}
                >
                    {renderRouteContent(
                        <FicheClientScreen />,
                        true,
                        displayCookiesBanner,
                        false
                    )}
                </PrivateRoute>
                <Route exact path={`/:lang${getPath(RouteTypes.LOGINSSO)}`}>
                    {renderRouteContent(
                        <LoginSsoScreen />,
                        false,
                        displayCookiesBanner
                    )}
                </Route>
                <PrivateRoute path={`/:lang${getPath(RouteTypes.DASHBOARD)}`}>
                    {renderRouteContent(
                        <DashboardScreen />,
                        true,
                        displayCookiesBanner,
                        true
                    )}
                </PrivateRoute>
                <Route path='*'>
                    <Redirect
                        to={`/${currentLang}${getPath(RouteTypes.DASHBOARD)}`}
                    />
                </Route>
            </Switch>
        </Suspense>
    );
};

const PrivateRoute: FC<{
    children: ReactElement;
    path: string;
    exact?: boolean;
    claim?: boolean;
}> = ({ children, path, exact = false, claim = true }) => {
    const accessToken = JSON.parse(
        localStorage.getItem('sso-response') ?? 'null'
    )?.access_token;
    const currentLang = useSelector(getLanguage) as string;
    const { userType } = useSelector((state: ProfileStates) => getUserProfileData(state) as UserProfileData) || {};
    const { getPath } = usePath();
    const logoutPath = `/${currentLang}${getPath(RouteTypes.LOGIN)}`;
    useExpireToken(logoutPath , userType);

    if (!!accessToken) {
        if (claim) {
            return (
                <Route
                    exact={exact}
                    path={path}
                    render={(props) => {
                        return children;
                    }}
                />
            );
        } else {
            return (
                <Redirect
                    to={`/${currentLang}${getPath(RouteTypes.DASHBOARD)}`}
                />
            );
        }
    } else {
        return <Redirect to={`/${currentLang}${getPath(RouteTypes.LOGIN)}`} />;
    }
};

export default Navigation;
