import React, { FC, useState } from 'react';
import { css } from 'styled-components';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

//CPOSM
import Community from 'saur-viseau-state-management/lib/common/models/Community';
import { getUserProfileData } from 'saur-viseau-state-management/lib/user-profile/selectors';
import { States as ProfileStates } from 'saur-viseau-state-management/lib';
import useSelectedCommunity from 'saur-viseau-state-management/lib/common/hooks/useSelectedCommunity';

//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Card from 'saur-design-sys-web/lib/Molecules/Card/Card';
import { Brands } from 'saur-design-sys-web/lib/BrandsConfig';

//Internal
import ListRow, { ListRowStyles } from './ListRow';
import TestIds from '../../../automated-tests-ids/ids';
import RainIcon from 'saur-design-sys-web/lib/Icons/RainIcon';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import UserProfileData from 'saur-viseau-state-management/lib/user-profile/models/ProfileUserInformation';
import Pagination from './Pagination';
import SearchContainer from './SearchContainer'

interface IProps {
    communitiesList: Community[];
    showLoadingError?: boolean;
    displayAdministration: (communityId: string) => void;
    setSelectedCommunity: () => void;
}

const CommunitiesList: FC<IProps> = ({
    communitiesList: fullCommunitiesList,
    showLoadingError = false,
    displayAdministration,
    setSelectedCommunity
}) => {
    const [paginatedList, setPaginatedList] = useState<Community[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCommunities, setfilteredCommunities] = useState(
        fullCommunitiesList
    );

    const userProfileData = useSelector((state: ProfileStates) =>
        getUserProfileData(state)
    ) as UserProfileData;
    const [_, updateSelectedCommunity] = useSelectedCommunity();


    //handlers
    const gotoAdministration = (communityId: string) => {
        onSelectCommunity(communityId, false);
        displayAdministration(communityId);
    };
    const onSelectCommunity = (selectedCommunityId: string, reload = true) => {

        // set selected community
        const selectedCommunity = fullCommunitiesList.find(
            ({ communityId }) => communityId == selectedCommunityId
        );
        // set in session storage
        updateSelectedCommunity(selectedCommunity?.name ?? '');

        // redirect to dashboard
        setSelectedCommunity();
        if(reload){
            window.location.reload()
        }
    };


    const showSearchBar = userProfileData?.userType == 'CPO_Saur'; //afficher pour les utilisateurs internes

    const showFilteredList =
        filteredCommunities && filteredCommunities.length > 0;
    const showNotFoundMsg =
        fullCommunitiesList &&
        fullCommunitiesList.length > 0 &&
        filteredCommunities &&
        filteredCommunities.length == 0;

    //render
    return (
        <>
            <Container>
                <Card
                    className={`communitiesList`}
                    automatedTestId={TestIds.menu.changeCommunityList}
                >
                    <SearchContainer {...{ showSearchBar, setfilteredCommunities, searchTerm, setSearchTerm, fullCommunitiesList }}/>
                    {showFilteredList && (
                        <Container className='communitiesList__rows'>
                            {paginatedList.map((community, index) => {
                                return (
                                    <ListRow
                                        key={community.communityId}
                                        communityId={community.communityId}
                                        logoName={community.logoName as Brands}
                                        communityName={
                                            community.longName || community.name
                                        }
                                        onSelectCommunity={onSelectCommunity}
                                        gotoAdministration={gotoAdministration}
                                    />
                                );
                            })}
                        </Container>
                    )}
                    {showNotFoundMsg && (
                        <Container className='communitiesList__emptyRows'>
                            <Word
                                textStyle='normalItalic'
                                value={i18next.t(
                                    'menu.changeCommunity.modal.notFound',
                                    { searchTerm }
                                )}
                            />
                        </Container>
                    )}

                    {showLoadingError && (
                        <Container className='ErrorMessageContainer'>
                            <RainIcon />
                            <Word
                                textStyle='normalItalic'
                                value={i18next.t(
                                    'menu.help.popup.sent_fail_message'
                                )}
                            />
                        </Container>
                    )}

                    
                    <Pagination
                        nextLabel={i18next.t(
                            'administration.user_manager.pagination.next'
                        )}
                        previousLabel={i18next.t(
                            'administration.user_manager.pagination.previous'
                        )}
                        onLabel={i18next.t(
                            'administration.user_manager.pagination.on'
                        )}
                        list={filteredCommunities}
                        setPageList={setPaginatedList}
                    />
                </Card>
            </Container>
        </>
    );
};

export const CommunitiesListStyles = css`
    .sds-container {
        .communitiesList {
            //height: 400px;
            padding: 1vw;
            transition: 700ms;
            overflow: inherit;
            .communitiesList__SearchBar {
                position: relative;
                background-color: #ffffff;
                border: 1px solid #2d4085;
                border-radius: 20px;
                width: 50%;
                min-width: 250px;
                input {
                    padding: 13px 35px 13px 15px;
                    &::-webkit-input-placeholder {
                        font-style: italic;
                    }
                }
                svg {
                    position: absolute;
                    right: 15px;
                    top: 12px;
                }
            }
            .communitiesList__rows {
                transition: 700ms;
                margin: 20px 0;
                height: 60%;
                overflow-y: scroll;
            }
            .communitiesList__emptyRows {
                padding: 20px;
            }
            .ErrorMessageContainer {
                justify-content: center;
                align-items: center;
                height: 100%;
                svg {
                    width: 80px;
                }
            }
        }

        ${ListRowStyles}
    }
`;

export default CommunitiesList;
