import { FC } from 'react';
import { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import { useTranslation } from 'react-i18next';

//CPOSM:
import { getUserProfileData } from 'saur-viseau-state-management/lib/user-profile/selectors';
import UserProfileData from 'saur-viseau-state-management/lib/user-profile/models/ProfileUserInformation';
import {
    isFeatureEnabled,
    getLanguage
} from 'saur-viseau-state-management/lib/settings/selectors';
import { States } from 'saur-viseau-state-management/lib';
import { ThemeType } from 'saur-design-sys-web/lib/BrandsConfig';

//Internal
import {
    RouteTypes,
    usePath
} from '../../../common/navigation';

type ProfileContentProps = {
    desktopMenuState: string;
};

const ProfileContent: FC<ProfileContentProps> = ({ desktopMenuState }) => {
    const { getPath } = usePath();
    const currentLang = useSelector(getLanguage) as string;
    const profilePath = `/${currentLang}${getPath(RouteTypes.PROFILE)}`;
    const { t } = useTranslation();

    const userProfileResponse = useSelector((state: States) =>
        getUserProfileData(state)
    ) as UserProfileData;

    const profileFeatureEnabled = useSelector((state: States) =>
        isFeatureEnabled(state, 'Profile-Information-Front')
    );

    const preferencesFeatureEnabled = useSelector((state: States) =>
        isFeatureEnabled(state, 'Notifications-Preferences-Front')
    );

    const userFullName =
        userProfileResponse?.firstName
            ?.toString()
            .concat(' ', userProfileResponse?.lastName ?? '') || '';

    return (
        <Container
            className={`profile-content ${
                desktopMenuState === 'full' ? 'full' : 'collapse'
            }`}
        >
            <Container className='profile-image-container'>
                <img
                    id={'TestIds.profile.picture.img'}
                    className='profile-image'
                    alt='close-menu-mobile'
                    src={
                        userProfileResponse?.profilPicture
                            ? 'data:image/png;base64,' +
                              userProfileResponse?.profilPicture
                            : '/assets/profile.jpg'
                    }
                    placeholder='/assets/profile.jpg'
                />
            </Container>

            {desktopMenuState === 'full' && (
                <Container className='profile-content-rigth'>
                    <Word
                        value={userFullName}
                        textStyle='normalMed'
                        className='profile-name'
                    />
                    {(profileFeatureEnabled || preferencesFeatureEnabled) && (
                        <Link to={profilePath}>
                            <Word
                                value={t('menu.see_profile')}
                                textStyle='normalReg'
                                className='profile-detail-link'
                            />
                        </Link>
                    )}
                </Container>
            )}
        </Container>
    );
};

export const ProfileContentStyles = css`
    .profile-content {
        flex-direction: row;
        margin-top: 50px;
        margin-bottom: 30px;

        &.collapse {
            justify-content: center;
        }
        .profile-image-container {
            width: 58px;
            height: 58px;
        }

        .profile-image {
            border-radius: 50%;
        }

        .profile-content-rigth {
            flex-direction: column;
            margin-left: 12px;
        }

        .profile-detail-link {
            color: #c7d3f5;
            text-decoration: underline;
            color: ${(props: ThemeType) => props.theme.texts.menuText};
            padding: 10px 0;
        }

        .profile-name {
            color: #ffffff;
        }
    }
`;

export default ProfileContent;
