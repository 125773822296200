import { Layer, LayersData } from 'saur-viseau-state-management/lib/settings/models/Layers';
import { BasicFilter } from '../screens/ReportingsScreen';


// get active sub layers of a single layer - Layer
export const getActiveLayer = (layer: Layer) => {

    let activeLayers : string[]= []
    if(layer.layers.length){
        layer.layers.forEach(singleLAyer => {
            activeLayers = activeLayers.concat([...getActiveLayer(singleLAyer)])
        });
        if(activeLayers.length){
            activeLayers.push(layer.code)
        }
    } else {
        if(layer.isSelected){
            activeLayers.push(layer.code)
        }
    }
    return activeLayers
}


// get active layers from the root layers data - LayersData
export const getActiveLayers = (layersState: LayersData | null) => {

    const layers = layersState?.layers || [] 
    let activeLayers : string[]= []
   
    layers.forEach(singleLAyer => {
        activeLayers =  activeLayers.concat([...getActiveLayer(singleLAyer)])
    });

    return activeLayers
}

export const getHistoryFilter = (historyState: any, setHistoryFilter: any) => {
    
    if(historyState != null && historyState.yearDisplayLimit){
        const historyObject: BasicFilter = {
          table: 'DimDate',
          column: 'YearDepth',
          values: Array.from(Array(historyState.yearDisplayLimit + 1).keys())
        };
        setHistoryFilter([historyObject])
        return historyObject
      }
    return null
}


export const sortBy = (array: any[], attribute: string, order: 1 | -1 ) => {
    // order 1 => croissant
    // order -1 => decroissant

    const sortFunc = (a: any, b: any) => {
       
            if (a[attribute] &&  b[attribute] && a[attribute].toLowerCase() < b[attribute].toLowerCase()) {
                return -1 * order;
            }
            if (a[attribute] &&  b[attribute] && a[attribute].toLowerCase() > b[attribute].toLowerCase()) {
                return 1 * order;
            }
            // a must be equal to b
            return 0;
    };
    const localArray = array?.length ? JSON.parse(JSON.stringify(array)) : []
    return localArray.sort(sortFunc);
};
