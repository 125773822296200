import React, { useState } from 'react';
import BottomArrowIcon from 'saur-design-sys-web/lib/Icons/BottomArrowIcon';
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';

type AccordionProps = {
    leftIcon?: React.ReactNode;
    title: string;
    children: React.ReactNode;
};

const Accordion: React.FC<AccordionProps> = ({
    leftIcon,
    title,
    children
}: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    return (
        <Container className='accordion-container'>
            <Container className='accordion-header' onClick={handleClick}>
                {leftIcon && (
                    <Container className='accordion-header_Left-icon'>
                        {leftIcon}
                    </Container>
                )}

                <Word
                    value={title}
                    textStyle='normalMed'
                    className='accordion-header_title menu-text'
                />
                <Container
                    className={`accordion-header_arrow-icon ${
                        isOpen ? 'open' : 'closed'
                    }`}
                >
                    <BottomArrowIcon />
                </Container>
            </Container>
            {isOpen && (
                <Container className={'accordion-content'}>
                    {children}
                </Container>
            )}
        </Container>
    );
};

export default Accordion;
