import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import LogoutIcon from 'saur-design-sys-web/lib/Icons/LogoutIcon';
import { Theme } from 'saur-design-sys-web/lib/BrandsConfig';

//CPOSM
import { States as ProfileStates } from 'saur-viseau-state-management/lib';
import { getUserProfileData } from 'saur-viseau-state-management/lib/user-profile/selectors';
import { getLanguage } from 'saur-viseau-state-management/lib/settings/selectors';
import { getAuthProvider } from '../../../common/authProvider';
//Internal

import CustomLink from '../../CustomLink';
import { RouteTypes, usePath } from '../../../common/navigation';
import { handleLogout } from '../../../LogoutHelper';
import UserProfileData from 'saur-viseau-state-management/lib/user-profile/models/ProfileUserInformation';

type LogoutMenuSectionProps = {
    desktopMenuState: string;
};

const LogoutMenuSection: FC<LogoutMenuSectionProps> = ({
    desktopMenuState
}) => {
    const { t } = useTranslation();
    const { getPath } = usePath();
    const themeContext = useContext(ThemeContext) as Theme;
    const currentLang = useSelector(getLanguage) as string;
    const { userType } = useSelector((state: ProfileStates) => getUserProfileData(state) as UserProfileData) || {};

    const keycloak = getAuthProvider();
    const dispatch = useDispatch();

    const onLogoutBtnClicked = () => {
        handleLogout(
            dispatch,
            keycloak,
            `${window.location.origin}/${currentLang}${getPath(
                RouteTypes.LOGIN
            )}`,
            userType
        );
    };
    return (
        <CustomLink
            automatedTestId={'logout-btn'}
            onClick={onLogoutBtnClicked}
            state={desktopMenuState === 'full' ? 'full' : 'collapse'}
            to={getPath(RouteTypes.LOGIN)}
        >
            <Container className={`button-children logout`}>
                <Container
                    className={`icon ${
                        desktopMenuState === 'full' ? 'full' : 'collapse'
                    }`}
                >
                    <LogoutIcon color={themeContext.logOutIconColor} />
                </Container>
                {desktopMenuState === 'full' && (
                    <Word
                        value={t('login.logout')}
                        textStyle='normalMed'
                        className='menu-text'
                    />
                )}
            </Container>
        </CustomLink>
    );
};

export const LogoutMenuSectionStyles = css`
    .logout {
        .icon {
            width: 50px;
            height: 50px;
            margin-right: 8px;
        }
        svg {
            width: 50px;
            height: 50px;
        }
    }
`;

export default LogoutMenuSection;
