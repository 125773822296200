import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { setUrlConfig } from 'saur-viseau-state-management/lib/urlsConfig';
import { initI18nWithResource } from 'saur-viseau-state-management/lib/i18n';
//microsoft-graph
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

const config = {
    KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
    KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOACK_CLIENT_ID,
    KEYCLOAK_SCOPE: process.env.REACT_APP_KEYCLOACK_SCOPE,
    CORE_BASE_URL: process.env.REACT_APP_CORE_BASE_URL,
    STORAGE_BASE_URL: process.env.REACT_APP_URL_TO_BLOBSTORAGE,
    TRANSLATION_STORAGE_BASE_URL: process.env.REACT_APP_URL_TO_BLOBSTORAGE,
    DEBUG_MODE: process.env.REACT_APP_DEBUG_MODE === 'true',
    CONTRACT_BASE_URL: process.env.REACT_APP_CONTRACT_BASE_URL,
    XAPI_HUBSPOT_BASE_URL: process.env.REACT_APP_XAPI_HUBSPOT_BASE_URL,
    B2B_ADMIN_BASE_URL: process.env.REACT_APP_B2B_ADMIN_BASE_URL,
};

if (
    config.CORE_BASE_URL &&
    config.STORAGE_BASE_URL &&
    config.CONTRACT_BASE_URL && 
    config.XAPI_HUBSPOT_BASE_URL &&
    config.B2B_ADMIN_BASE_URL
) {
    setUrlConfig(
        config.CORE_BASE_URL,
        config.STORAGE_BASE_URL,
        config.CONTRACT_BASE_URL,
        '', //NOTIF_BASE_URL
        config.B2B_ADMIN_BASE_URL,
        config.XAPI_HUBSPOT_BASE_URL, //xApiHubspotUrl
        config.DEBUG_MODE
    );
}

initI18nWithResource();

Providers.globalProvider = new Msal2Provider({
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_SAUR_MICROSOFT_ENTRA_TENANT_ID}`
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
