import { CookieSetting } from 'saur-viseau-state-management/lib/settings/state';
import Cookies from 'universal-cookie';

export type Option = {
    id: number;
    title: string;
    desc: string;
    default: boolean;
    active: boolean;
};

export function getComputedData(cookieSettings: any, t: any): Array<Option> {
    const requiredCookie = { id: 2, rule: 'required', accepted: true };
    const optionsList: Array<Option> = [];
    let option: Option | null;

    for (const cookie of [requiredCookie, ...cookieSettings]) {
        option = toOption(cookie, t);
        if (option) {
            optionsList.push(option);
        }
    }

    return optionsList;
}

export function toOption(cookie: CookieSetting, t: any): Option | null {
    switch (cookie.rule) {
        case 'required':
            return {
                id: 2,
                title: t('cookiesConfig.panel.requiredCookie.title'),
                desc: t('cookiesConfig.panel.requiredCookie.desc', {
                    soft: 'Piwik'
                }),
                default: true,
                active: cookie.accepted
            };
        case 'analytic':
            return {
                id: 1,
                title: t('cookiesConfig.panel.optionAnalytic.title'),
                desc: t('cookiesConfig.panel.optionAnalytic.desc', {
                    soft: 'Piwik'
                }),
                default: false,
                active: cookie.accepted
            };
        default:
            return null;
    }
}
export function toCookie(option: Option): CookieSetting | null {
    switch (option.id) {
        case 1:
            return {
                id: option.id,
                rule: 'analytic',
                accepted: option.active
            };
        case 2:
            return {
                id: option.id,
                rule: 'required',
                accepted: true
            };
        default:
            return null;
    }
}

export function listenCookieChange(
    callback: (arg0: { newValue: string }) => void,
    interval = 1000
) {
    const cookies = new Cookies();
    let userAnalyticCookie = cookies.get('analytic');
    setInterval(() => {
        const cookie = cookies.get('analytic');
        compareCookies(cookie, userAnalyticCookie, callback);
    }, interval);
}

export function compareCookies(
    cookie: any,
    userAnalyticCookie: any,
    callback: (arg0: { newValue: string }) => void
) {
    if (cookie !== userAnalyticCookie) {
        callback({
            newValue: cookie
        });
    }
}
