const DASHBOARD = 'dashboard_';
const PAYMENTS = 'payments_';
const MENU = 'menu_';

const ID_VALUES = {
    welcomeUser: `${DASHBOARD}welcome-user`,
    userName: `${DASHBOARD}header-user-name`,
    accessMyProfile: `${DASHBOARD}access-my-profile`,
    contracts: `${DASHBOARD}contracts`,
    payments: `${DASHBOARD}payements`,
    consumption: `${DASHBOARD}consumption`,
    billings: `${DASHBOARD}billings`,
    help: `${DASHBOARD}help`,
    dashboard: `${DASHBOARD}dashboard`,
    exchange: `${DASHBOARD}exchange`,
    logout: `${DASHBOARD}logout`,
    menuDesktopButton: `${DASHBOARD}menu-desktop-button`,
    widgetInvoiceButtonDebitInvoice: `${PAYMENTS}widget-invoice-button-debit-invoice`,
    createAccount: `create_account-redirect-button`,
    CookiesBanner: 'cookies-banner',
    CookiesPanel: 'CookiesPanel',
    footer: {
        footer: 'cpo_footer-container',
        links: {
            cookies: 'footer_cookies-button'
        }
    },
    menu: {
        changeCommunity: `${MENU}change-collectivity`,
        changeCommunityList: `change-collectivityList`,
        searchCommunityList: `search-collectivityList`,
        communityItem: `comunity-item`,
        openCollectivityListModal: `${MENU}open-collectivityclist-modal`,
        closeCollectivityListModal: `${MENU}close-collectivityclist-modal`,
        communitySettingBtn : `comunity-setting-btn`,
        communitySelectBtn : `comunity-select-btn`
    },
    sharePoint: {
        SharepointFileList: {
            sharepointFileList: `share-point-file-list`
        },
        fileItem: {
            fileLine: `file-item`
        }
    }
};
export default ID_VALUES;
